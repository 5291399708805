import format, { IFormatNumberOptions } from 'format-number';

/**
 * Get number as ordinal string (e.g. "1st", "2nd", "3rd", etc)
 */
export const ordinal = (value: number) => {
  const modTen = value % 10;
  const modHundo = value % 100;

  // If number ends in 1, but is not 11
  if (modTen === 1 && modHundo !== 11) return `${value}st`;
  // If number ends in 2, but is not 12
  if (modTen === 2 && modHundo !== 12) return `${value}nd`;
  // If number ends in 3, but is not 13
  if (modTen === 3 && modHundo !== 13) return `${value}rd`;
  // the rest...
  return `${value}th`;
};

export const usd = (value = 0, includeCents = true) => {
  if (includeCents) {
    return `$ ${value.toFixed(2)}`;
  }

  // Round and format
  return `$ ${Math.round(value)}`;
};

export type BigNumberOptions = IFormatNumberOptions & {
  placeholder?: string;
};

const defaultOps: BigNumberOptions = {
  integerSeparator: ',',
};

export const bigNumber = (num: number | undefined, { placeholder = '-', ...opts }: BigNumberOptions = {}) =>
  num == null ? placeholder : format({ ...defaultOps, ...opts })(num);

export const kmFormat = (num: number, shouldSmallGetDecimals = true): string => {
  const oneMillion = 1.0e6;
  const oneThousand = 1.0e3;

  if (num === 0) return '0';
  if (!num) return '';
  if (num < oneThousand) return shouldSmallGetDecimals ? num.toString() : num.toFixed(0);
  if (num < oneMillion) {
    return `~${(num / oneThousand).toFixed(1)}k`;
  }
  return `~${(num / oneMillion).toFixed(2)}m`;
};

export const roundNumber = (num: number, places: number): number => {
  const multiplier = 10 ** places;

  // will multiple by 1 or -1 at the end to negate the Math.abs
  // call needed to properly round negative numbers down
  const factor = Math.sign(num);

  return (Math.round(Math.abs(num) * multiplier) / multiplier) * factor;
};

/**
 *
 * @param num the number to round
 * @param places the number of decimal places to round to (default is `0`)
 * @param isPercent a flag that if set to `true` will prevent rounding anything above a 99
 * so as not to show 100% resolved with a mismatched fixType
 * @returns "-" if no number is provided and the number to the correct decimal places if defined
 */
export const roundA11yReportDataPoint = (num?: number, places: number = 0, isPercent = true): string => {
  // default display for null, undefined, or NaN values
  if (num == null || Number.isNaN(num)) {
    return '-';
  }

  // really small numbers can just be shown as 0
  if (num > 0 && num < 0.01) {
    return '< 0.01';
  }

  // if it's a percent data point and greater than 99 and we're rounding to whole numbers
  // skip rounding and return to prevent mismatched fixType label
  if (isPercent && num > 99 && places === 0) {
    return num.toString().split('.')[0];
  }

  // mathematically round the number to specified places
  const roundedNum = roundNumber(num, places);

  // // we won't show decimals for 0 values and need to remove
  // // any possible negative signs that are hanging around
  if (roundedNum === 0) {
    return '0';
  }

  // fix the number to specific decimal places
  // in case trailing 0s chopped
  return roundedNum.toFixed(places);
};
