import { PolicyAction } from '@audioeye/auth-client';
import {
  A11yRulesConfigAvailableDTO,
  A11yRulesConfigLineageDTO,
  A11yRulesConfigStoredValueDTO,
  A11yRulesConfigValueWithRulesVersionDTO,
  MonoResourceType,
} from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useUserCanForResource } from 'hooks/useUserCan';

import {
  clearCurrentEntityVersion,
  getAllRulesVersions,
  getCurrentGlobalVersion,
  getLineage,
  getResolvedReportingConfigForSite,
  updateCurrentEntityVersion,
  updateCurrentGlobalVersion,
} from '../../services/api/rules.api';

enum RulesCacheKeys {
  All = 'all_rules',
  Lineage = 'lineage',
  Global = 'global',
}

export enum ReportingVersionType {
  Release = 'release',
  Shadow = 'shadow',
}

export const useGetCurrentGlobalRulesVersion = (
  enabled: boolean = true,
): UseQueryResult<A11yRulesConfigStoredValueDTO, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.Global],
    queryFn: getCurrentGlobalVersion,
    retry: false,
    enabled,
  });
};

export const useGetAllRulesVersions = (): UseQueryResult<A11yRulesConfigAvailableDTO[], AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.All],
    queryFn: getAllRulesVersions,
    retry: false,
    select: (data) =>
      // Remove duplicate reportingVersion values, keeping the last (newest) occurance
      // Note: API returns the versions in descending order (oldest to newest)
      [
        ...data
          .reduce(
            (acc, item) => acc.set(item.reportingVersion, item),
            // using map (preserves ordering)
            new Map(),
          )
          .values(),
      ],
  });
};

export const useUpdateGlobalReportingVersions = (): UseMutationResult<
  void,
  AxiosError,
  A11yRulesConfigStoredValueDTO
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCurrentGlobalVersion,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [RulesCacheKeys.Global] });
    },
  });
};

export const useSetEntitytReportingVersionOverride = (): UseMutationResult<
  { entityId: string },
  AxiosError,
  A11yRulesConfigStoredValueDTO & { entityId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ entityId, ...dto }) => updateCurrentEntityVersion(entityId, dto).then(() => ({ entityId })),
    onSuccess: async ({ entityId }) => {
      await queryClient.invalidateQueries({ queryKey: [RulesCacheKeys.Lineage, entityId] });
    },
  });
};

export const useClearEntityReportingVersionOverride = (): UseMutationResult<
  { entityId: string },
  AxiosError,
  { entityId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ entityId }) => {
      await clearCurrentEntityVersion(entityId);
      return { entityId };
    },
    onSuccess: async ({ entityId }) => {
      await queryClient.invalidateQueries({ queryKey: [RulesCacheKeys.Lineage, entityId] });
    },
  });
};

export const useGetReportingVersionLineageForEntity = (
  entityId: string | undefined,
): UseQueryResult<A11yRulesConfigLineageDTO, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.Lineage, entityId],
    queryFn: () => getLineage(entityId!),
    retry: false,
    enabled: entityId != null,
  });
};

export const useGetResolvedReportingConfigForSite = <TOut = A11yRulesConfigValueWithRulesVersionDTO>({
  siteId,
  reportShareId,
  select,
}: {
  siteId: string | undefined;
  reportShareId?: string;
  select?: (input: A11yRulesConfigValueWithRulesVersionDTO) => TOut;
}): UseQueryResult<TOut, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.Lineage, siteId, reportShareId],
    queryFn: () => getResolvedReportingConfigForSite(siteId!, reportShareId),
    retry: false,
    enabled: siteId != null && siteId !== '',
    select,
  });
};

export const useUserCanSeeReportingVersionOverridesFor = (entityId: string) => {
  return useUserCanForResource({
    action: PolicyAction.READ,
    resourceType: MonoResourceType.A11Y_RULES_CONFIG,
    dto: { id: entityId },
  });
};

export const useUserCanEditReportingVersionOverridesFor = (entityId: string | undefined) => {
  return useUserCanForResource({
    action: PolicyAction.UPDATE,
    resourceType: MonoResourceType.A11Y_RULES_CONFIG,
    dto: entityId != null ? { id: entityId } : undefined,
  });
};

// eslint-disable-next-line max-len
// this is a magic value https://github.com/audioeye/audioeye-mono/blob/da74b452de1cc7fcfc3c92decd66cdcd77b9f923/src/database/A11yRulesConfig.entity.ts#L17
const GLOBAL_CONFIG_ID = '0773aee8-fffb-4b38-ba6a-4e7e0446369c';

export const useUserCanSeeGlobalReportingVersionOverrides = (enabled: boolean = true) => {
  return useUserCanForResource({
    action: PolicyAction.READ,
    resourceType: MonoResourceType.A11Y_RULES_CONFIG,
    dto: enabled ? { id: GLOBAL_CONFIG_ID } : undefined,
  });
};

export const useUserCanEditGlobalReportingVersionOverrides = (enabled: boolean = true) => {
  return useUserCanForResource({
    action: PolicyAction.UPDATE,
    resourceType: MonoResourceType.A11Y_RULES_CONFIG,
    dto: enabled ? { id: GLOBAL_CONFIG_ID } : undefined,
  });
};
