import { AccountDTO, CustomerType, UserDTO } from '@audioeye/mono-client';

import { useGetAccountById } from '../accountQueries';
import { useGetAppUser } from './appUserQueries';

export type AppUserAndSitesResult = {
  user: UserDTO | undefined;
  account: AccountDTO | undefined;
  isResellerPartner: boolean;
  isEnterpriseUser: boolean;
  isLoading: boolean;
};
export const useAppUser = (): AppUserAndSitesResult => {
  const { data: user, isLoading: isAppUserLoading } = useGetAppUser();
  const { data: account, isLoading: isAccountLoading } = useGetAccountById(user?.accountId);
  return {
    user: user != null && account != null ? user : undefined,
    account: account != null ? account : undefined,
    isResellerPartner: account?.customerType === CustomerType.RESELLER,
    isEnterpriseUser: account?.customerType === CustomerType.ENTERPRISE,
    isLoading: isAppUserLoading || isAccountLoading,
  };
};
