import { StyledCSS } from 'styled';

export const root: StyledCSS = () => ({
  minHeight: '100vh',
});

export const main: StyledCSS = ({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  paddingTop: '32px',
  overflow: 'unset',

  [theme.device.small]: {
    padding: 0,
    overflow: 'auto',
  },
});
