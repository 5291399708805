import { SiteDTO, UserDTO } from '@audioeye/mono-client';
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { storage } from 'services/storage';
import { DISABLE_REFETCH } from 'state/DISABLE_REFETCH';
// eslint-disable-next-line import/no-cycle
import { useGetSite, useGetSiteCount } from 'state/sites/siteQueries';
// eslint-disable-next-line import/no-cycle
import { useGetUserById } from 'state/users/userQueries';

import { useGetDefaultSubBrand } from '../subBrandQueries';

export enum AppUserKeys {
  AppUserID = 'app_user_id',
  ImpersonationStatus = 'impersonation_status',
}

export const useImpersonation = (): [boolean, (value: boolean) => void] => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: [AppUserKeys.ImpersonationStatus],
    queryFn: () => storage.local.getImpersonating() ?? false,
    initialData: storage.local.getImpersonating(),
    ...DISABLE_REFETCH,
  });
  const callback = useCallback(
    (newValue: boolean) => {
      storage.local.setImpersonating(newValue);
      queryClient.setQueryData([AppUserKeys.ImpersonationStatus], newValue);
    },
    [queryClient],
  );

  return [data ?? false, callback];
};

export const useAppUserId = (): [string | null, (value: string | undefined) => void] => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: [AppUserKeys.AppUserID],
    queryFn: () => storage.local.getAppUserId() || null,
    initialData: storage.local.getAppUserId() || null,
    ...DISABLE_REFETCH,
  });
  const callback = useCallback(
    (newValue: string | undefined) => {
      storage.local.setAppUserId(newValue);
      queryClient.setQueryData([AppUserKeys.AppUserID], newValue || null);
    },
    [queryClient],
  );
  return [data, callback];
};

export const useSetAppUserId = (): ((userId: string | undefined) => void) => {
  const [_, setAppUserId] = useAppUserId();
  return setAppUserId;
};

export const useGetAppUserId = (): string => {
  const [appUserId] = useAppUserId();
  return appUserId ?? '';
};

export const useGetAppUser = (): UseQueryResult<UserDTO, Error> => {
  const userId = useGetAppUserId();
  return useGetUserById(userId);
};

export const useLoadAppUserSiteByID = (siteId: string): UseQueryResult<SiteDTO, Error> => useGetSite(siteId);

export const useGetAppUserSitesCount = (): UseQueryResult<number, AxiosError> => {
  const { data: user } = useGetAppUser();
  const { data: subBrand } = useGetDefaultSubBrand(user?.accountId);
  return useGetSiteCount(
    {
      subBrandId: subBrand?.id,
    },
    user != null,
  );
};
