import { ComponentConfigurationDTO, PortalComponentType, PresentationConfigDTO } from '@audioeye/mono-client';
import { NEW_PRESENTATION_CONFIG_COMPONENT_VALUES } from 'config/PRESENTATION_CONFIG_DEFAULTS';
import { useParams } from 'react-router';

const EXCLUDED_VENDASTA_COMPONENTS = new Set<PortalComponentType>([
  PortalComponentType.SUBSCRIPTION,
  PortalComponentType.LEGAL_SUPPORT,
]);

type RouteParams = {
  presentationConfigId?: string;
};

export const useConvertDefaultComponentValuesToDTO = (): {
  DEFAULT_PRESENTATION_CONFIG: PresentationConfigDTO;
  VENDASTA_DEFAULT_PRESENTATION_CONFIG: PresentationConfigDTO;
} => {
  const { presentationConfigId } = useParams<RouteParams>();

  const convertedValues: ComponentConfigurationDTO[] = Array.from(
    NEW_PRESENTATION_CONFIG_COMPONENT_VALUES,
    ([key, value]) => ({
      type: key,
      ...value,
    }),
  );

  return {
    DEFAULT_PRESENTATION_CONFIG: {
      id: 'default-presentation-config-id',
      integrationId: presentationConfigId ?? '',
      componentsConfig: {
        components: convertedValues,
      },
    },
    VENDASTA_DEFAULT_PRESENTATION_CONFIG: {
      id: 'vendasta-presentation-config-id',
      integrationId: presentationConfigId ?? '',
      componentsConfig: {
        components: convertedValues.filter(({ type }) => !EXCLUDED_VENDASTA_COMPONENTS.has(type)),
      },
      featureSettings: {
        isSingleSiteOnly: true,
      },
    },
  };
};
