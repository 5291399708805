import {
  AnnounceContext,
  AnnounceMessage,
  AnnounceMode,
  AnnounceValue,
  Text,
  ThemeProvider as InclusiveUI,
} from '@audioeye/inclusive-ui';
import React, { PropsWithChildren } from 'react';
import { theme } from 'theme/theme';

import { MenloFontFamily } from './font/menlo/MenloFontFamily';
import { GlobalStyle } from './global';

export interface ThemeProviderProps {
  message?: AnnounceMessage;
  mode?: AnnounceMode;
}

export interface AnnouncerProps {
  announce: AnnounceValue;
  message?: AnnounceMessage;
  mode?: AnnounceMode;
  role?: string;
}

const Announcer: React.FC<AnnouncerProps> = ({ announce, message = '', mode = 'polite', role = 'log' }) => {
  React.useEffect(() => {
    const timeout = 3000;
    announce(message, mode, timeout);
  }, [announce, message, mode]);

  return (
    <Text role={role} aria-live={mode}>
      {message}
    </Text>
  );
};

export const ThemeProvider: React.FC<PropsWithChildren<ThemeProviderProps>> = ({ children }, ...p) => (
  <InclusiveUI theme={theme}>
    <GlobalStyle />
    <MenloFontFamily />
    <AnnounceContext.Consumer>
      {(announce) => (
        <>
          <Announcer announce={announce} {...p} />
          {children}
        </>
      )}
    </AnnounceContext.Consumer>
  </InclusiveUI>
);
