import { IntegrationType, MonoResourceType } from '@audioeye/mono-client';

import { useGetAccountById, useGetAccounts } from '../../../state/accountQueries';
import { useGetCompartmentById, useGetCompartments } from '../../../state/compartmentQueries';
import { useGetIntegrationById, useGetIntegrations } from '../../../state/integrationQueries';
import { useGetRawPolicies } from '../../../state/policyQueries';
import {
  useGetSiteCreationDefaultById,
  useGetSiteCreationDefaults,
} from '../../../state/siteCreationDefaults/siteCreationDefaultQueries';
import { useLoadSiteGroupByID, useLoadSiteGroupsPaginated } from '../../../state/siteGroups/siteGroupQueries';
import { useGetSite, useGetSites } from '../../../state/sites/siteQueries';
import { useGetSSOConfigurationById, useGetSSOConfigurations } from '../../../state/ssoQueries';
import { useGetSubBrandById, useGetSubBrands } from '../../../state/subBrandQueries';
import { useGetSubscriptionById, useGetSubscriptions } from '../../../state/subscription/subscriptionQueries';
import { useGetUserGroupById, useGetUserGroups } from '../../../state/userGroupQueries';
import { useGetUserById, useGetUsers } from '../../../state/users/userQueries';

export enum AuthResourceType {
  PrincipalEndUser = 'end_user',
  User = 'User',
  Compartment = 'Compartment',
  SSOConfiguration = 'SSOConfiguration',
  UserGroup = 'UserGroup',
  Policy = 'AuthCasbinRule',
}

export const POLICY_RESOURCE_WILDCARD = '*' as const;

export const POLICY_RESOURCE_MAPPING = {
  mono: {
    // Add a default for all resource types
    ...Object.values(MonoResourceType)
      .sort()
      .reduce(
        (acc, resourceType) => ({
          ...acc,
          [resourceType]: {},
        }),
        {},
      ),
    [MonoResourceType.ACCOUNT]: {
      query: useGetAccounts,
      getById: useGetAccountById,
    } as const,
    [MonoResourceType.SUB_BRAND]: {
      query: useGetSubBrands,
      getById: useGetSubBrandById,
    } as const,
    [MonoResourceType.USER]: {
      labelKey: 'email',
      query: useGetUsers,
      getById: useGetUserById,
    } as const,
    [MonoResourceType.SITE]: {
      labelKey: 'domain',
      query: useGetSites,
      getById: useGetSite,
    } as const,
    [MonoResourceType.SUBSCRIPTION]: {
      query: useGetSubscriptions,
      getById: useGetSubscriptionById,
    } as const,
    [MonoResourceType.SITE_CREATION_DEFAULT]: {
      query: useGetSiteCreationDefaults,
      getById: useGetSiteCreationDefaultById,
    } as const,
    [MonoResourceType.SITE_GROUP]: {
      query: useLoadSiteGroupsPaginated,
      getById: useLoadSiteGroupByID,
    } as const,
    [MonoResourceType.PARTNER_INTEGRATION]: {
      query: useGetIntegrations,
      getById: useGetIntegrationById,
      filter: {
        integrationType: IntegrationType.PARTNER_INTEGRATION,
      },
    } as const,
    [MonoResourceType.RESELLER_INTEGRATION]: {
      query: useGetIntegrations,
      getById: useGetIntegrationById,
      filter: {
        integrationType: IntegrationType.RESELLER_INTEGRATION,
      },
    } as const,
  } as const,
  auth: {
    [AuthResourceType.User]: {
      labelKey: 'email',
      query: useGetUsers,
      getById: useGetUserById,
    },
    [AuthResourceType.Compartment]: {
      query: useGetCompartments,
      getById: useGetCompartmentById,
    },
    [AuthResourceType.SSOConfiguration]: {
      query: useGetSSOConfigurations,
      getById: useGetSSOConfigurationById,
    },
    [AuthResourceType.UserGroup]: {
      query: useGetUserGroups,
      getById: useGetUserGroupById,
    },
    [AuthResourceType.Policy]: {
      query: useGetRawPolicies,
    },
  } as const,
} as const;

type PolicyResourceMappingType = typeof POLICY_RESOURCE_MAPPING;
export type PolicyResourceSystem = keyof PolicyResourceMappingType;
export type PolicyResourceType = typeof POLICY_RESOURCE_WILDCARD | AuthResourceType | MonoResourceType;
