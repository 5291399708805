import { SiteDTO, SiteSubscriptionStatus, SubscriptionDTO } from '@audioeye/mono-client';
import { isPast } from 'date-fns';

import { APP_CONFIG } from '../../../config';
import { extractDomainFromUrl } from '../../format/general';

/**
 * Determines whether a site is in a state to be cancelled or not
 */
export const isCancellable = (s: SubscriptionDTO) =>
  s.status === SiteSubscriptionStatus.ACTIVE || s.status === SiteSubscriptionStatus.IN_TRIAL;
/**
 * An active site is a site with a current, paid subscription.
 * This is either an active site, a site in trial, or a site with a non-renewing or scheduled cancellation.
 */
const ACTIVE_STATUSES: SiteSubscriptionStatus[] = [
  SiteSubscriptionStatus.ACTIVE,
  SiteSubscriptionStatus.IN_TRIAL,
  SiteSubscriptionStatus.NON_RENEWING,
  SiteSubscriptionStatus.CANCELLATION_REQUESTED,
];
export const isInactive = (site: SiteDTO | undefined) =>
  site !== undefined ? !(site.status && ACTIVE_STATUSES.includes(site.status)) : false;
/*
    https://miro.com/app/board/o9J_lf2SGuM=/
    // free -> managed:
      has site been managed?
        if trial period is over, payment is required
        if not, payment is not required
    // managed -> managed:
        if trial period is over, payment is required
        if not, payment is not required
*/
export const isPaymentRequired = (s: SubscriptionDTO | undefined) =>
  Boolean(
    s?.chargebeeSubscription && s.chargebeeSubscription.trial_end && isPast(s.chargebeeSubscription.trial_end * 1000),
  );

export const sortByDomain = (a: { domain: string }, b: { domain: string }): number =>
  extractDomainFromUrl(a.domain) > extractDomainFromUrl(b.domain) ? 1 : -1;

/**
 * Generate site JS script
 */
export const getSiteJS = (site: SiteDTO, includeAsync: boolean = true) => {
  const hash = site?.hash;
  const domain = APP_CONFIG.audioeye.cdnDomain;

  if (!hash) {
    throw new Error('Site hash required to generate JS');
  }

  // eslint-disable-next-line
  const siteJS = `<script type="text/javascript">!function(){var b=function(){window.__AudioEyeSiteHash = "${hash}"; var a=document.createElement("script");a.src="${domain}/aem.js";a.type="text/javascript";${
    includeAsync ? 'a.setAttribute("async","")' : ''
  };document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();</script>`;

  return siteJS;
};

/**
 * Get the site hash from a site
 */
export const getSiteHash = (site: SiteDTO) => {
  const hash = site?.hash;

  if (!hash) {
    throw new Error('Site id not found on site');
  }

  return hash;
};
