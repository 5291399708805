export enum TabState {
  OVERVIEW = 'overview',
  INSTRUCTIONS = 'instructions',
}

export enum SpringtimeMutationKeys {
  UPDATE_BANNER_FOR_SINGLE_SITE = 'updateBannerForSingleSite',
  UPDATE_DEFAULT_BANNER = 'updateDefaultBanner',
  UPDATE_SITE_TO_COOKIE_CONSENT_BANNER = 'updateSiteToCookieConsentBanner',
  UPDATE_SITE_TO_BANNER_SET = 'updateSiteToBannerSet',
  UPDATE_SINGLE_SITE_VARIANT = 'updateSingleSiteVariant',
  UPDATE_VARIANT = 'updateVariant',
  UPDATE_BANNER_SET_AND_BANNERS_FOR_SITE = 'updateBannerSetAndBannersForSite',
  UPDATE_DEFAULT_BANNER_SET = 'updateDefaultBannerSet',
  CHANGE_WEBSITE_STATUS = 'changeWebsiteStatus',
}

export enum SpringtimeBannerMutationKeys {
  BRANDING = 'branding',
  PLACEMENT = 'placement',
  CONTENT = 'content',
  CONSENT_TYPE = 'consent_type',
  BANNERSET_CONTENT = 'bannerset_content',
  BANNERSET_PLACEMENT = 'bannerset_placement',
  BANNERSET_BRANDING = 'bannerset_branding',
}
