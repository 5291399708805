import { ForgotPasswordDTO } from '@audioeye/auth-client';
import { CreateUserDto, LoginDto, UserApi } from '@audioeye/mono-client';

import { APP_CONFIG } from '../../../config';
import { getQueryParam } from '../../url';
import { client } from '../client';
import { AuthStorage } from './AuthStorage';
import { AuthClient } from './types';

export const mockOauthAuthorizationPath = '/mocked/oauth/authorize/endpoint';

const userApi = new UserApi(undefined, APP_CONFIG.api.url, client);

export const mockAuthClient = (): AuthClient => {
  const getSession = async () => {
    const mockSession = AuthStorage.getSession();
    if (mockSession) return mockSession;
    throw new Error('No current user');
  };

  const register = async (data: CreateUserDto) => {
    const result = await userApi.createUser(data);
    return result.data;
  };

  const verifyChallenge = async () => {};

  const login = async (dto: LoginDto) => {
    const result = await client.post('/auth/login', dto);
    const { payload } = result.data;

    AuthStorage.setSession({
      userId: payload.sub,
      payload,
      idToken: 'fake.id.token',
      accessToken: 'fake.access.token',
      refreshToken: 'fake-refresh-token',
    });
    return getSession();
  };

  const logout = async () => {
    AuthStorage.clearSession();
  };

  /**
   * Initiate forgotPassword flow
   */
  const forgotPassword = async ({ email }: ForgotPasswordDTO) => ({
    message: 'success',
    email,
  });

  const forgotPasswordSubmit = async (email: string, code: string, password: string): Promise<'SUCCESS'> => {
    // Mock invalidate code
    if (code === '000000') {
      throw new Error('Invalid verification code provided, please try again.');
    }
    if (email && password) {
      // appease the TS gods
    }

    return 'SUCCESS';
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    if (oldPassword !== 'Password123!' || newPassword !== 'Password123!') {
      throw new Error('Incorrect username or password.');
    }

    return 'SUCCESS';
  };

  /**
   * To mock oauth authentication we must pass the mocked user credentials
   * as query paramters in the form of mockEmail=useremail&mockPassword=password.
   * This will set the mock auth session and then reload the page
   */
  const oauthAuthorize = async () => {
    const username = getQueryParam('mockEmail');
    const password = getQueryParam('mockPassword');

    if (username && password) {
      await login({ username, password });
      window.location.reload();
    } else {
      window.location.replace(mockOauthAuthorizationPath);
    }
  };

  return {
    getSession,
    register,
    login,
    logout,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    oauthAuthorize,
    verifyChallenge,
  };
};
