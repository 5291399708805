import {
  CompartmentDTO,
  CompartmentPaginatedResponseDTO,
  QueryCompartmentPaginateEntitiesDTO,
} from '@audioeye/auth-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getCompartmentById, getCompartments } from '../services/api/auth/compartment.api';

enum CompartmentCacheKey {
  ById = 'compartment_by_id',
  All = 'all_compartments',
}

export const useGetCompartmentById = (
  compartmentId: string | null | undefined,
): UseQueryResult<CompartmentDTO, AxiosError> =>
  useQuery({
    queryKey: [CompartmentCacheKey.ById, compartmentId],
    queryFn: () => getCompartmentById(compartmentId || ''),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!compartmentId,
  });

export const useGetCompartments = (
  dto: QueryCompartmentPaginateEntitiesDTO,
): UseInfiniteQueryResult<InfiniteData<CompartmentPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [CompartmentCacheKey.All, dto],
    queryFn: ({ pageParam }) => getCompartments({ ...dto, cursor: pageParam == null ? undefined : pageParam }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
  });
