/**
 * Api http client with global config
 * TODO: auth header defaults for authenticated requests
 * Consider custom authenticated client redux middleware?
 */
import { AuthenticationApi } from '@audioeye/auth-client';
import { datadogLogs } from '@datadog/browser-logs';
import axios, { AxiosRequestConfig } from 'axios';
import { AEStatsigClient } from 'util/AEStatsigClient';
import { StatsigOverrideAdaptor } from 'util/StatsigOverrideAdaptor';

import { APP_CONFIG } from '../../config';
import { AuthStorage } from './auth/AuthStorage';
import { getClientBearerToken } from './auth/getClientBearerToken';

export const client = axios.create({
  baseURL: APP_CONFIG.api.url,
  timeout: process.env.GATSBY_MOCK_API === 'true' ? 1000 : 0,
});

const authenticationApi = new AuthenticationApi(undefined, APP_CONFIG.auth.serviceUrl, client);
const fetchBearerHeader = async () => {
  const authSession = AuthStorage.getSession();
  if (!authSession) {
    return;
  }

  try {
    const { data } = await authenticationApi.currentSession({
      ...authSession,
      shouldForceRefresh: true,
    });
    AuthStorage.setSession(data);
    return `Bearer ${data.accessToken}`;
  } catch (_error) {
    /* The token has expired and we need to clear out state */
    AuthStorage.clearSession();
  }
};

client.interceptors.request.use(async (req) => {
  // Conditionally attach bearer auth
  const token = await getClientBearerToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }

  try {
    const { gate, dynamicConfig } = (
      AEStatsigClient.getContext().options.overrideAdapter as StatsigOverrideAdaptor
    ).getAllOverrides();
    if (gate) {
      // Via @audioeye/nestjs-statsig
      req.headers['x-statsig-overrides'] = JSON.stringify(gate);
    }
    if (dynamicConfig) {
      req.headers['x-statsig-dynamic-config-overrides'] = JSON.stringify(dynamicConfig);
    }
  } catch (_) {
    /* ignore me */
  }

  return req;
});

let fetchingPromise: Promise<string | undefined> | null = null;
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Log network errors to datadog
    const status = error?.response?.status;
    if (!status || status >= 500) {
      datadogLogs.logger.error(error.message || 'Network error', error);
    }

    const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };
    if ((status === 401 || !status) && !originalRequest._retry) {
      originalRequest._retry = true;

      // update both the default and the in-flight request
      if (status === 401) {
        fetchingPromise = fetchingPromise || fetchBearerHeader();
        await fetchingPromise;
        fetchingPromise = null;
      }
      return client(originalRequest);
    }
    return Promise.reject(error);
  },
);
