/**
 * This api client is not to be confused with the chargebee service.
 * It is part of the audioeye api services that integrates with chargebee's API
 * to provide additional functionality to the chargebee JS client.
 * For more information see the chargebee API based integration here:
 * https://www.chargebee.com/checkout-portal-docs/api-checkout.html#call-flow
 */
import {
  AccountApi,
  ChargebeeApi,
  ChargebeeCouponDto,
  HostedManagePaymentPageResponseDTO,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const accountApi = new AccountApi(undefined, APP_CONFIG.api.url, client);
const chargebeeApi = new ChargebeeApi(undefined, APP_CONFIG.api.url, client);
export const getManagePaymentMethodsHostedPage = async (
  accountId: string,
): Promise<HostedManagePaymentPageResponseDTO> => {
  const result = await accountApi.getHostedManagePayment(accountId);
  return result.data.hosted_page;
};

export const validateCoupon = async (couponCode: string): Promise<ChargebeeCouponDto> => {
  const result = await chargebeeApi.getCoupon(couponCode);

  return result.data;
};

export const syncSubscription = async (siteId: string): Promise<void> => {
  await chargebeeApi.syncSubscription(siteId);
};
