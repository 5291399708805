import { APP_CONFIG } from '../../../config';
import { AuthStorage } from './AuthStorage';

/**
 * Helper function to provide bearer token to api client.
 * NOTE: ONLY to be consumed by the api client.
 */
export const getClientBearerToken = async () => {
  // Mock session auth token
  if (APP_CONFIG.api.mock) {
    const mockToken = APP_CONFIG.api.mockAuthToken;
    return mockToken;
  }

  try {
    return AuthStorage.getBearerToken();
  } catch (err) {
    // Do nothing
  }
};
