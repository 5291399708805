import { ExpertAuditApi, ExpertAuditDTO, ExpertAuditResponseDTO } from '@audioeye/mono-client';
import { DateFormat, formatDate } from 'util/formatDate';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const expertAuditApi = new ExpertAuditApi(undefined, APP_CONFIG.api.url, client);

export const getAllSiteExpertAudits = async (siteId: string): Promise<ExpertAuditResponseDTO[]> => {
  const result = await expertAuditApi.getSiteExpertAuditData(siteId);

  return result.data;
};

export const downloadExpertAuditPDF = async (audit: ExpertAuditDTO): Promise<void> => {
  await expertAuditApi.getExpertAuditPDF(audit.id, {
    responseType: 'blob',
    transformResponse: (data: Blob) => {
      // name is similar to how it is displayed in audit table
      const name = `expert_audit_${formatDate(audit.auditStatusUpdateDate, DateFormat.SnakeShortDateYear)}.pdf`;

      // create the pdf and object url
      const pdfFile = new File([data], name, { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfFile);

      // create the link and click it
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();

      // clean up
      URL.revokeObjectURL(url);
    },
  });
};
