/* eslint-disable @typescript-eslint/naming-convention */
import { deepMerge, palette, theme as baseTheme, ThemeType as GrommetTheme } from '@audioeye/inclusive-ui';
import { rem } from 'polished';

const TOOLTIP_MAX_WIDTH = rem('450px');
export const DASHBOARD_CARD_PADDING = rem(32);

// Override grommet theme values
const grommetOverrides: Partial<GrommetTheme> = {
  global: {
    focus: {
      border: {
        color: 'transparent',
      },
      outline: {
        color: palette['purple-600'],
        size: '4px',
      },
    },
    size: {
      xlarge: '950px',
    },
    colors: {
      brand: palette['purple-600'],
      alert: palette['red-800'],
    },
    hover: {
      background: {
        color: palette['gray-100'],
        opacity: 1,
      },
    },
    active: {
      background: {
        color: palette['gray-600'],
        opacity: 1,
      },
      color: { light: palette.white },
    },
    drop: {
      zIndex: `${baseTheme.zIndex.dropdown}`,
    },
    input: {
      font: {
        weight: 400,
      },
    },
    font: {
      family: 'Inter, Helvetica, Arial, sans-serif',
      size: '1rem',
    },
  },
  checkBox: {
    color: palette['purple-600'],
    /* toggle : {
      color: { dark : palette.cyan, light: palette.cyan},
      background: { dark: palette.cyan, light:  palette.cyan }
    } */
  },
  anchor: {
    color: palette['purple-600'],
    hover: {
      extend: `
        color: ${palette['purple-400']};
      `,
    },
    extend: `
      &:active {
        color: ${palette['purple-900']} !important;
      }
    `,
  },
  avatar: {
    size: {
      small: '34px',
    },
  },
  card: {
    container: {
      elevation: 'none',
      round: {
        size: '8px',
      },
      border: {
        size: '2px',
        style: 'solid',
        color: palette['gray-100'],
      },
    },
  },
  heading: {
    extend: ({ level, size }) => {
      const getWeight = () => {
        switch (level) {
          case 1: {
            switch (size) {
              case 'large': {
                return 'font-weight: 300;';
              }
              case 'medium': {
                return 'font-weight: 700;';
              }
              default: {
                return;
              }
            }
          }
          case 2: {
            switch (size) {
              case 'medium':
              case 'large': {
                return 'font-weight: 400;';
              }
              case 'small': {
                return 'font-weight: 700;';
              }
              default: {
                return '';
              }
            }
          }
          default: {
            return '';
          }
        }
      };
      return `
        ${getWeight()}
        ${baseTheme.device.small} {
          text-align: center;
        }
      `;
    },
    level: {
      1: {
        font: {
          weight: '300',
        },
        large: {
          size: rem(40),
          height: '1.5',
        },
        medium: {
          size: rem(36),
          height: '1.5',
        },
      },
      2: {
        font: {
          size: rem(24),
          height: '1.5',
          weight: 500,
        },
        large: {
          size: rem(24),
          height: '1.5',
        },
        small: {
          size: rem(18),
          height: '1.5',
        },
      },
      3: {
        font: {
          weight: 600,
        },
        large: {
          size: rem(18),
          height: '1.5',
        },
      },
      4: {
        font: {
          weight: 400,
        },
        large: {
          size: rem(20),
          height: '1.5',
        },
      },
      5: {
        font: {
          weight: 500,
          size: rem(14),
          height: '1.5',
        },
        large: {
          maxWidth: 'unset',
        },
        medium: {
          maxWidth: 'unset',
        },
      },
    },
  },
  formField: {
    border: false,
    label: {
      margin: { bottom: 'small', vertical: 'small', horizontal: '0' },
      weight: 700,
    },
  },
  layer: {
    overlay: {
      background: palette['shadow-rgba'],
    },
    zIndex: `${baseTheme.zIndex.layer}`,
  },
  paragraph: {
    large: {
      size: '16px',
      height: '1.5',
      maxWidth: '100%',
    },
    medium: {
      size: '16px',
      height: '1.5',
      maxWidth: '100%',
    },
    small: {
      maxWidth: '100%',
    },
    extend: `
      ${baseTheme.device.small} {
        text-align: center;
      }
    `,
  },
  text: {
    large: {
      size: '16px',
      height: '1.5rem',
    },
    medium: {
      size: '16px',
      height: '1.5rem',
    },
    small: {
      size: '12px',
      height: '1.5rem',
    },
    extend: ({ variant }) => {
      if (variant === 'value-xl') {
        return `
          font-weight: 700;
          font-size: ${rem(32)};
          line-height: 1.5rem;
          color: ${palette.black};
        `;
      }
      return `
        &.caption {
          font-weight: 400;
          font-size: ${rem(14)};
          line-height: 1.5rem;
          color: ${palette['gray-600']};
        }
        &.description-small {
          font-weight: 500;
          font-size: ${rem(14)};
          line-height: 1.5rem;
          text-transform: uppercase;
          color: ${palette['gray-600']};
          letter-spacing: 0.84px;
        }
      `;
    },
  },

  tabs: {
    gap: '1rem',
    header: {
      border: {
        color: palette['gray-100'],
        side: 'bottom',
        size: '1px',
      },
    },
  },
  tab: {
    color: 'black',
    border: {
      side: 'bottom',
      color: 'transparent',
      size: '2px',
      active: {
        color: palette['purple-600'],
      },
      hover: {
        color: palette['purple-800'],
      },
    },
    pad: {
      vertical: 'small',
      horizontal: 'small',
    },
    margin: {
      left: 'none',
      right: 'none',
      top: 'none',
      bottom: '-1px',
    },
    active: {
      color: palette['purple-600'],
      background: palette['purple-50'],
    },
    hover: {
      color: palette['purple-800'],
      background: palette['purple-50'],
    },
  },
  tooltip: {
    maxWidth: TOOLTIP_MAX_WIDTH,
  },
};

export const theme: GrommetTheme = deepMerge(baseTheme, grommetOverrides);
