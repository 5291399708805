import { AuthenticationSessionDTO } from '@audioeye/auth-client';
import { AuthenticationSessionDTO as MonoAuthenticationSessionDTO } from '@audioeye/mono-client';

import { storage } from '../../storage';

const AUTH_SESSION_KEY = 'aeye_auth_session';

export const SESSION_CHANGED_EVENT = 'SESSION_CHANGED_EVENT' as const;
export class AuthStorage {
  static setSession(session: AuthenticationSessionDTO | MonoAuthenticationSessionDTO | null): void {
    if (session?.accessToken === AuthStorage.getSession()?.accessToken) {
      return;
    }

    if (!session) {
      AuthStorage.clearSession();
    } else {
      storage.local.set(AUTH_SESSION_KEY, session);
    }

    window.dispatchEvent(new CustomEvent(SESSION_CHANGED_EVENT, { detail: session }));
  }

  static getSession(): AuthenticationSessionDTO | MonoAuthenticationSessionDTO | null {
    const output = storage.local.get(AUTH_SESSION_KEY);

    const values = Object.values(output ?? {});
    if (!values.length || values.some((value) => value === undefined)) {
      return null;
    }

    return output ?? null;
  }

  static clearSession(): void {
    storage.local.remove(AUTH_SESSION_KEY);
    storage.local.remove('sso_state_cache');
    storage.local.remove('app_user_id');
    storage.local.remove('impersonating');
    storage.local.remove('statsig_user_data');
    storage.local.remove('statsig_overrides');
    window.dispatchEvent(new CustomEvent(SESSION_CHANGED_EVENT, { detail: undefined }));
  }

  static getBearerToken(): string | undefined {
    const session = AuthStorage.getSession();
    return session?.accessToken;
  }
}
