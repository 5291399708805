import { useReportingVersion } from 'hooks/useReportingVersion';

export function useShouldUseRulesAutoRems({ siteOrSiteGroupId }: { siteOrSiteGroupId: string }): {
  data: boolean;
  isPending: boolean;
} {
  const { data: version, isPending } = useReportingVersion({ siteId: siteOrSiteGroupId, reportShareId: undefined });

  return { data: parseInt(version?.split('.')[0] ?? '0', 10) >= 8, isPending };
}
