/**
 * sometimes we receive UUIDs, and sometimes we receive UUIDs with the hyphens removed.
 * This inserts hyphens into the string
 * @param id - the input string to format as UUID
 */
export const tryInsertHyphens = (id: string | null | undefined): string | null => {
  if (!id || id.length !== 32) {
    return id ?? null;
  }
  return `${id.slice(0, 8)}-${id.slice(8, 12)}-${id.slice(12, 16)}-${id.slice(16, 20)}-${id.slice(20)}`;
};

/**
 * sometimes (particularly for testing) we need to remove hyphens from a UUID
 */
export const removeHyphens = (id: string): string => id.replace(/-/g, '');
