import { AuthenticationSessionDTO } from '@audioeye/auth-client';
import {
  CreateUserAgreementDTO,
  CreateUserDto,
  MigrateUserToAccountDTO,
  ProvisionUserFromSSODTO,
  QueryUserPaginateEntitiesDTO,
  UpdateUserDto,
  UserAgreementResponseDTO,
  UserDTO,
  UserPaginatedResponseDTO,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { track } from '../../services/analytics/analytics';
import { auth } from '../../services/api/auth';
import { AuthStorage } from '../../services/api/auth/AuthStorage';
import {
  anonymizeUser,
  generateApiKey,
  getUserById,
  getUsers,
  migrateUserToAccount,
  provisionUserFromSSO,
  updateProfile,
} from '../../services/api/user.api';
import { userAgreementApi } from '../../services/api/userAgreement.api';
import { AnalyticsEvent } from '../../types/analytics';
// eslint-disable-next-line import/no-cycle
import { useSetAppUserId } from '../appUser/appUserQueries';
// eslint-disable-next-line import/no-cycle
import { AuthCacheKeys } from '../auth/authQueries';

export enum UserCacheKeys {
  All = 'all_users',
  UserData = 'user_data',
  User = 'user',
  Users = 'users',
  IssueReporting = 'issue_reporting',
  ProvisionUserAfterSSO = 'provision_user_after_sso',
}

export const useGetUsers = (
  dto: QueryUserPaginateEntitiesDTO,
  isEnabled: boolean = true,
): UseInfiniteQueryResult<InfiniteData<UserPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [UserCacheKeys.All, dto],
    queryFn: ({ pageParam }) => getUsers({ ...dto, cursor: pageParam == null ? undefined : pageParam }),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: isEnabled,
    initialPageParam: '',
  });

export const useGetUserById = (
  userId: string | null | undefined,
  shouldRefetchOnMount = false,
): UseQueryResult<UserDTO, AxiosError> =>
  useQuery({
    queryKey: [UserCacheKeys.User, userId],
    queryFn: () => getUserById(userId || ''),
    enabled: !!userId,
    refetchOnMount: shouldRefetchOnMount,
  });

export const useRegisterUser = (): UseMutationResult<UserDTO, AxiosError, CreateUserDto> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => auth.register(params),
    onSuccess: async (result) => {
      if (!result) {
        return;
      }
      // Clear out the users list if we add a new user
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.Users] });
    },
  });
};

export const useUpdateUser = (): UseMutationResult<UserDTO, AxiosError, { userId: string; data: UpdateUserDto }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateProfile,
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.Users] });
    },
  });
};

export const useMigrateUserToAccount = (): UseMutationResult<void, AxiosError, MigrateUserToAccountDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: migrateUserToAccount,
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, params.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.Users] });
    },
  });
};

export const useGenerateApiKey = (): UseMutationResult<UserDTO, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: generateApiKey,
    onSuccess: async (_result, userId) => {
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, userId] });
    },
  });
};

export const useMarkUserAsDeleted = (): UseMutationResult<void, Error, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: anonymizeUser,
    onSuccess: async (_result, userId) => {
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, userId] });
    },
  });
};

export const useProvisionUserFromSSO = (): UseMutationResult<
  void,
  AxiosError,
  Omit<ProvisionUserFromSSODTO, 'payload'> & AuthenticationSessionDTO
> => {
  const setAppUser = useSetAppUserId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: provisionUserFromSSO,
    onSuccess: async (_, dto) => {
      if (!dto) {
        return;
      }
      AuthStorage.setSession(dto);
      queryClient.setQueryData([AuthCacheKeys.AuthSession], dto);
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, dto.userId] });
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.User, dto.userId] });
      setAppUser(dto.userId);
      track(AnalyticsEvent.LOGIN_SUCCESS, { label: 'email' });
    },
  });
};

export const useStoreUserAgreement = (): UseMutationResult<
  UserAgreementResponseDTO,
  AxiosError,
  CreateUserAgreementDTO
> =>
  useMutation({
    mutationFn: ({ userId, type }) =>
      userAgreementApi.addUserAgreement({ userId, type }).then((axiosResponse) => axiosResponse.data),
  });
