import { ExpertAuditDTO, ExpertAuditResponseDTO } from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { downloadExpertAuditPDF, getAllSiteExpertAudits } from 'services/api/expertAudit.api';

enum ExpertAuditCacheKeys {
  All = 'all_audits',
}

export const useGetAllSiteExpertAudits = (siteId: string): UseQueryResult<ExpertAuditResponseDTO[], AxiosError> =>
  useQuery({
    queryKey: [ExpertAuditCacheKeys.All, siteId],
    queryFn: () => getAllSiteExpertAudits(nullthrows(siteId)),
    enabled: !!siteId,
  });

export const useDownloadExpertAuditPDF = (): UseMutationResult<void, AxiosError, ExpertAuditDTO> =>
  useMutation({ mutationFn: downloadExpertAuditPDF });
