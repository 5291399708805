import { ForgotPasswordDTO, MagicLinkUserDTO } from '@audioeye/auth-client';
import { AuthenticationSessionDTO, LoginDto } from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStatsigOverrideAdaptor } from 'util/AEStatsigClient';

import { track } from '../../services/analytics/analytics';
import { auth } from '../../services/api/auth';
import { AuthStorage, SESSION_CHANGED_EVENT } from '../../services/api/auth/AuthStorage';
import { storage } from '../../services/storage';
import { AnalyticsEvent } from '../../types/analytics';
import { tryInsertHyphens } from '../../util/formatUUID';
// eslint-disable-next-line import/no-cycle
import { useImpersonation, useSetAppUserId } from '../appUser/appUserQueries';
// eslint-disable-next-line import/no-cycle
import { useCurrentSubBrandId } from '../subBrandQueries';
// eslint-disable-next-line import/no-cycle
import { UserCacheKeys } from '../users/userQueries';

export enum AuthCacheKeys {
  AuthSession = 'auth_session',
}

interface CustomEventMap {
  [SESSION_CHANGED_EVENT]: CustomEvent<AuthenticationSessionDTO | null>;
}
declare global {
  interface Window {
    // adds definition to Document, but you can do the same with HTMLElement
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
  }
}

export const useSetSession = () => {
  const queryClient = useQueryClient();
  const setAppUserId = useSetAppUserId();

  return useCallback(
    (currentSession: AuthenticationSessionDTO | null) => {
      const queryClientSession = queryClient.getQueryData<AuthenticationSessionDTO | null>([AuthCacheKeys.AuthSession]);
      if (currentSession?.accessToken === queryClientSession?.accessToken) {
        return;
      }

      queryClient.setQueryData([AuthCacheKeys.AuthSession], currentSession);
      AuthStorage.setSession(currentSession);
      setAppUserId(currentSession?.userId);
    },
    [queryClient],
  );
};

export const useRetrieveSession = (): UseQueryResult<AuthenticationSessionDTO, AxiosError> => {
  const queryClient = useQueryClient();
  const setSession = useSetSession();

  // Force react query auth session to match the refetched session
  const onSessionChanged = useCallback(
    (event: CustomEvent<AuthenticationSessionDTO | null>) => {
      setSession(event.detail);
    },
    [queryClient],
  );

  useEffect(() => {
    window.addEventListener(SESSION_CHANGED_EVENT, onSessionChanged);
    return () => {
      window.removeEventListener(SESSION_CHANGED_EVENT, onSessionChanged);
    };
  }, [onSessionChanged]);

  return useQuery({
    queryKey: [AuthCacheKeys.AuthSession],
    queryFn: async () => {
      const session = await auth.getSession();
      setSession(session);

      return session;
    },
    initialData: AuthStorage.getSession(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 1000 * 60 * 60 * 24,
  });
};

export const useGetSingleSignOnConfigId = (): string | null => {
  const { data: sessionData } = useRetrieveSession();
  return tryInsertHyphens(sessionData?.payload?.identities?.[0]?.providerName);
};

export const useLogin = (): UseMutationResult<AuthenticationSessionDTO, AxiosError, LoginDto> => {
  const setAppUser = useSetAppUserId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => auth.login(params),
    onSettled: async (session) => {
      if (!session) {
        return;
      }
      queryClient.setQueryData([AuthCacheKeys.AuthSession], session);
      await queryClient.invalidateQueries({ queryKey: [UserCacheKeys.UserData, session.userId] });
      setAppUser(session.userId);
      track(AnalyticsEvent.LOGIN_SUCCESS, { label: 'email' });
    },
  });
};

export const useLogout = (): UseMutationResult<void, AxiosError> => {
  const queryClient = useQueryClient();
  const setAppUser = useSetAppUserId();
  const [_, setIsImpersonating] = useImpersonation();
  const overrideAdapter = useStatsigOverrideAdaptor();
  return useMutation({
    mutationFn: auth.logout,
    onSettled: () => {
      queryClient.clear();
      setAppUser(undefined);
      setIsImpersonating(false);
      overrideAdapter.removeAllOverrides();
      storage.session.clear();
    },
  });
};

export const useForgotPassword = (): UseMutationResult<void, AxiosError, ForgotPasswordDTO> =>
  useMutation({ mutationFn: auth.forgotPassword });

export const useForgotPasswordSubmit = (): UseMutationResult<
  'SUCCESS',
  AxiosError,
  { email: string; code: string; password: string }
> => useMutation({ mutationFn: ({ email, code, password }) => auth.forgotPasswordSubmit(email, code, password) });

export const useVerifyChallenge = (): UseMutationResult<void, AxiosError, MagicLinkUserDTO> => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (p) => auth.verifyChallenge(p),
    onSettled: (_, error) => {
      if (error) {
        navigate('/400');
      } else {
        navigate('/');
      }
    },
  });
};

export const useStartImpersonation = (): ((userId: string, subBrandId: string) => void) => {
  const setAppUser = useSetAppUserId();
  const [_, setIsImpersonating] = useImpersonation();
  const [, setCurrentSubBrandId] = useCurrentSubBrandId();
  return (userId: string, subBrandId: string) => {
    setAppUser(userId);
    setCurrentSubBrandId(subBrandId);
    setIsImpersonating(true);
  };
};

export const useStopImpersonationWithId = (): ((userId: string | undefined) => void) => {
  const setAppUser = useSetAppUserId();
  const [_, setIsImpersonating] = useImpersonation();
  const [, setCurrentSubBrandId] = useCurrentSubBrandId();
  return (userId: string | undefined) => {
    setIsImpersonating(false);
    if (userId) {
      setAppUser(userId);
    }
    setCurrentSubBrandId('');
  };
};
