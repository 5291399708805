/**
 * Auth related api client
 */
import { APP_CONFIG } from '../../../config';
import { mockAuthClient } from './authClient.mock';
import { authServiceClient } from './authServiceClient';
import { AuthClient } from './types';

const authClient: AuthClient = authServiceClient();

/**
 * Singleton auth client
 */
const getAuthClient = (): AuthClient => {
  // Mock auth api in config
  if (APP_CONFIG.api.mock) {
    return mockAuthClient();
  }

  return authClient;
};

export const auth = getAuthClient();
