export enum AnalyticsEvent {
  API_EXCEPTION = 'api_exception',
  CLIENT_EXCEPTION = 'client_exception',
  LOGIN_START = 'login_start',
  LOGIN_SUCCESS = 'login_success',
  SIGNUP_START = 'signup_start',
  SIGNUP_SUCCESS = 'signup_success',
  SUBSCRIPTION_CANCEL = 'subscription_cancellation',
  INSTALL_INSTRUCTIONS_EMAILED = 'Install Instructions Emailed',
  VIDEO_PLAYED = 'Video Played',
  VIDEO_PAUSED = 'Video Paused',
  VIDEO_COMPLETED = 'Video Completed',
  SITE_DASHBOARD_VIEWED = 'Site Dashboard Viewed',
  ACCESSIBILITY_STATEMENT_CODE_COPIED = 'Accessibility Statement Code Copied',
  LEGAL_SUPPORT_REQUESTED = 'Legal Support Requested',
  STAR_PLAN_GENERATED = 'Star Plan Generated',
  STAR_PLAN_DOWNLOADED = 'Star Plan Downloaded',
  AFFECTED_PAGE_OPENED_WITH_OSS = 'Affected Page Opened with OSS',
  SDK_TOKEN_COPIED = 'sdk_token_copied',
  PROTECTION_COMPONENT_VIEWED = 'Protection Status Component Viewed',
}
