import { MutatePresentationConfigDTO, PresentationConfigApi, PresentationConfigDTO } from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const presentationConfigApi = new PresentationConfigApi(undefined, APP_CONFIG.api.url, client);

export const getPresentationConfig = async (presentationConfigId: string): Promise<PresentationConfigDTO> => {
  const result = await presentationConfigApi.get(presentationConfigId);
  return result.data;
};

export const getPresentationConfigBySingleSignOnConfigId = async (
  singleSignOnConfigId: string,
): Promise<PresentationConfigDTO> => {
  const result = await presentationConfigApi.getBySingleSignOnConfigID(singleSignOnConfigId);
  return result.data;
};

export const createPresentationConfig = async (
  presentationConfig: MutatePresentationConfigDTO,
): Promise<PresentationConfigDTO> => {
  const result = await presentationConfigApi.create(presentationConfig);
  return result.data;
};

export const updatePresentationConfig = async (
  id: string,
  presentationConfig: MutatePresentationConfigDTO,
): Promise<PresentationConfigDTO> => {
  const result = await presentationConfigApi.update(id, presentationConfig);
  return result.data;
};

export const removePresentationConfig = async (presentationConfigId: string): Promise<void> => {
  await presentationConfigApi.remove(presentationConfigId);
};
