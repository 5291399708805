import {
  GetPlansResponseDto,
  QuerySubscriptionPaginateEntitiesDTO,
  SubscriptionDTO,
  SubscriptionPaginatedResponseDTO,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';

import { getSubscriptionById, getSubscriptionPlans, getSubscriptions } from '../../services/api/subscription.api';

export enum SubscriptionCacheKeys {
  All = 'all_subscriptions',
  SubscriptionPlans = 'subscription_plans',
  SubscriptionById = 'subscription_by_id',
}

export const useFetchSubscriptionPlans = (coupons?: string[]): UseQueryResult<GetPlansResponseDto[]> =>
  useQuery({
    queryKey: [SubscriptionCacheKeys.SubscriptionPlans, coupons],
    queryFn: () => getSubscriptionPlans(coupons),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useGetSubscriptionById = (
  subscriptionId: string | undefined | null,
): UseQueryResult<SubscriptionDTO, AxiosError> =>
  useQuery({
    queryKey: [SubscriptionCacheKeys.SubscriptionById, subscriptionId],
    queryFn: () => getSubscriptionById(nullthrows(subscriptionId)),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: !!subscriptionId,
  });

export const useGetSubscriptions = (
  dto: QuerySubscriptionPaginateEntitiesDTO,
): UseInfiniteQueryResult<InfiniteData<SubscriptionPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SubscriptionCacheKeys.All, dto],
    queryFn: ({ pageParam }) => getSubscriptions({ ...dto, cursor: pageParam == null ? undefined : pageParam }),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    initialPageParam: '',
  });
