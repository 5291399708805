import {
  CancellationReason,
  ChargebeeInvoicePaginatedResponseDTO,
  ChargebeeInvoiceQuery,
  CreateSitesDTO,
  LegalFormSubmitDto,
  MutateSiteDTO,
  MutateSubscriptionDTO,
  QuerySitePaginateEntitiesDTO,
  ReportShareDto,
  SiteDTO,
  SitePaginatedResponseDTO,
  SiteSubscriptionAttestationResponseDTO,
  SiteValidityResponseDto,
  StarReportLinkDto,
  SubscriptionDTO,
  TransferSiteDTO,
  UpdateSiteConfigDto,
  UpdateUserSiteSubscriptionDto,
  UserSiteResponseDto,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';

import { syncSubscription } from '../../services/api/chargebee.api';
import { getSiteBySiteGroupById } from '../../services/api/siteGroup.api';
import {
  cancelSite,
  createSites,
  deleteSite,
  getPaginatedSites,
  getReportShare,
  getSite,
  getSiteAttestation,
  getSiteCount,
  getSiteDomainForLegacyId,
  GetSiteDto,
  getSiteServiceLevel,
  getSiteURLValidity,
  getStarPlanLink,
  getTrustedCertification,
  provisionSite,
  submitLegalSupportEmail,
  transferManuallyBilledSite,
  updateSite,
  updateSiteConfig,
  updateSiteUrl,
} from '../../services/api/sites.api';
import { getUserChargebeeInvoices, updateSubscription } from '../../services/api/subscription.api';
import { SubscriptionCacheKeys } from '../subscription/subscriptionQueries';
// eslint-disable-next-line import/no-cycle

export enum SiteCacheKeys {
  SiteAsAdmin = 'site_as_admin',
  LegacySiteDomain = 'legacy_site_domain',
  UserSite = 'user_site',
  UserSiteBySiteGroupId = 'user_site_by_site_group_id',
  UserSitesPaginated = 'user_sites_paginated',
  SitesPaginated = 'sites_paginated',
  SiteAttestation = 'site_attestation',
  SiteCount = 'site_count',
  SinglePageScan = 'single_page_scan',
  SiteInvoices = 'site_invoices',
  SiteIssueReportingData = 'site_issue_reporting_data',
  SiteIssueReportingDataByCode = 'site_issue_reporting_data_by_code',
  SitePageIssueReportingData = 'site_page_issue_reporting_data',
  SiteServiceLevel = 'site_service_level',
  SiteURLValid = 'site_url_valid',
  AeScriptSiteGraderDetection = 'ae_script_site_grader_detection',
  ReportShare = 'report_share',
  RecentlyViewedSites = 'recently_viewed_sites',
}

const clearCacheOnMutation = async (queryClient: QueryClient, siteId?: string): Promise<void> => {
  // Clear out all the sites queries when there is a mutation
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSite, siteId] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.SiteCount] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSitesPaginated] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.SitesPaginated] });
  await queryClient.invalidateQueries({ queryKey: [SubscriptionCacheKeys.All] });
  await queryClient.invalidateQueries({ queryKey: [SiteCacheKeys.UserSitesPaginated], exact: false });
  await queryClient.invalidateQueries({ queryKey: [SubscriptionCacheKeys.SubscriptionById] });
};

export const useGetSite = (siteId: string | null | undefined): UseQueryResult<SiteDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.UserSite, siteId],
    queryFn: () => getSite(nullthrows(siteId)),
    enabled: !!siteId,
  });

export const useLoadSiteDomainForLegacyId = (legacyId: number | undefined): UseQueryResult<string, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.LegacySiteDomain, legacyId],
    queryFn: () => getSiteDomainForLegacyId(legacyId || 0),
    enabled: !!legacyId,
    retry: false,
  });

export const useLoadSiteBySiteGroupId = (siteGroupId: string | undefined): UseQueryResult<SiteDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.UserSiteBySiteGroupId, siteGroupId],
    queryFn: () => getSiteBySiteGroupById(nullthrows(siteGroupId)),
    enabled: !!siteGroupId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export const useGetSites = (
  params: QuerySitePaginateEntitiesDTO,
  isEnabled?: boolean,
): UseInfiniteQueryResult<InfiniteData<SitePaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SiteCacheKeys.UserSitesPaginated, params],
    queryFn: ({ pageParam }) =>
      getPaginatedSites({ pageSize: 25, ...params, cursor: pageParam == null ? undefined : pageParam }),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    placeholderData: (prevData) => prevData,
    enabled: isEnabled ?? true,
    initialPageParam: '',
  });

export const useGetSiteServiceLevel = (siteId: string | null | undefined) =>
  useQuery({
    queryKey: [SiteCacheKeys.SiteServiceLevel, siteId],
    queryFn: () => getSiteServiceLevel(nullthrows(siteId)),
    enabled: !!siteId,
    retry: false,
  });

export const useGetSiteURLValidity = (url: string): UseQueryResult<SiteValidityResponseDto, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.SiteURLValid, url],
    queryFn: () => getSiteURLValidity(url),
    enabled: !!url,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export const useProvisionUserSite = (): UseMutationResult<SiteDTO, AxiosError, MutateSiteDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => provisionSite(dto),
    onSuccess: async (result) => {
      await clearCacheOnMutation(queryClient, result.id);
    },
  });
};

export const useCreateSites = (): UseMutationResult<void, AxiosError, CreateSitesDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => createSites(dto),
    onSuccess: async () => {
      await clearCacheOnMutation(queryClient);
    },
  });
};

export const useUpdateUserSite = (): UseMutationResult<
  SiteDTO,
  AxiosError,
  GetSiteDto & UpdateUserSiteSubscriptionDto
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSite,
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useUpdateSiteUrl = (): UseMutationResult<
  UserSiteResponseDto,
  AxiosError,
  { siteId: string; url: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSiteUrl,
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useUpdateUserSiteConfig = (): UseMutationResult<SiteDTO, AxiosError, GetSiteDto & UpdateSiteConfigDto> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSiteConfig,
    onSuccess: async (result, params) => {
      if (!result) {
        return;
      }
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useCancelSite = (): UseMutationResult<
  SiteDTO,
  AxiosError,
  GetSiteDto & { subscriptionId: string; cancelReason?: CancellationReason }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => cancelSite(params),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({
        queryKey: [SubscriptionCacheKeys.SubscriptionById, params.subscriptionId],
      });
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};
export const useTransferManuallyBilledSite = (): UseMutationResult<
  SiteDTO,
  AxiosError,
  GetSiteDto & TransferSiteDTO
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: transferManuallyBilledSite,
    onSuccess: async (_, params) => {
      await clearCacheOnMutation(queryClient, params.siteId);
    },
  });
};

export const useDeleteSite = (): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteSite,
    onSuccess: async (_, params) => {
      await clearCacheOnMutation(queryClient, params);
    },
  });
};

export const useSyncSiteSubscription = (): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: syncSubscription,
    onSuccess: async (_, siteId) => {
      await clearCacheOnMutation(queryClient, siteId);
    },
  });
};

export const useGetSiteAttestation = (
  siteId: string | undefined,
): UseQueryResult<SiteSubscriptionAttestationResponseDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.SiteAttestation, siteId],
    queryFn: () => getSiteAttestation(nullthrows(siteId)),
    enabled: siteId != null,
  });

export const useSubmitLegalSupportEmail = (): UseMutationResult<
  void,
  AxiosError,
  { userId: string; data: LegalFormSubmitDto }
> => useMutation({ mutationFn: ({ userId, data }) => submitLegalSupportEmail(userId, data) });

export const useGetStarPlanLink = (): UseMutationResult<StarReportLinkDto, AxiosError, GetSiteDto> =>
  useMutation({ mutationFn: getStarPlanLink });

export const useGetTrustedCertification = (siteId: string): UseQueryResult<Blob, AxiosError> =>
  useQuery({ queryKey: [siteId], queryFn: () => getTrustedCertification(siteId) });

export const useGetSiteCount = (
  dto: QuerySitePaginateEntitiesDTO,
  isEnabled?: boolean,
): UseQueryResult<number, AxiosError> =>
  useQuery({ queryKey: [SiteCacheKeys.SiteCount, dto], queryFn: () => getSiteCount(dto), enabled: isEnabled });

export const useFetchReportShareParams = ({
  reportShareId,
  enabled = true,
}: {
  reportShareId: string;
  enabled?: boolean;
}): UseQueryResult<ReportShareDto, AxiosError> =>
  useQuery({
    queryKey: [SiteCacheKeys.ReportShare, reportShareId],
    queryFn: () => getReportShare(reportShareId),
    enabled,
  });

export const useGetInvoices = ({
  subBrandId,
  pageSize,
  cursor,
}: ChargebeeInvoiceQuery & { subBrandId: string }): UseInfiniteQueryResult<
  InfiniteData<ChargebeeInvoicePaginatedResponseDTO & { subBrandId: string }>,
  AxiosError
> =>
  useInfiniteQuery({
    queryKey: [SiteCacheKeys.SiteInvoices, subBrandId, pageSize, cursor],
    queryFn: ({ pageParam }) =>
      getUserChargebeeInvoices({ subBrandId, pageSize, cursor: pageParam == null ? undefined : pageParam }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
  });

export const useUpdateSubscription = (): UseMutationResult<
  SubscriptionDTO,
  AxiosError,
  { id: string; dto: MutateSubscriptionDTO }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (mutationObject) => updateSubscription(mutationObject.id, mutationObject.dto),
    onSuccess: async (result, params) => {
      await queryClient.invalidateQueries({
        queryKey: [SubscriptionCacheKeys.SubscriptionById, params.id],
      });
      await clearCacheOnMutation(queryClient);
      return result;
    },
  });
};
