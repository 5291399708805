import { StatsigClient } from '@statsig/js-client';
import { useStatsigClient } from '@statsig/react-bindings';
import { APP_CONFIG } from 'config';

import * as local from '../services/storage/local';
import { StatsigOverrideAdaptor } from './StatsigOverrideAdaptor';

export const AEStatsigClient = new StatsigClient(
  APP_CONFIG.statsig.clientSideID,
  local.get('statsig_user_data') ?? { userID: local.get('app_user_id') ?? undefined },
  {
    environment: {
      tier: APP_CONFIG.statsig.envTier as unknown as 'staging' | 'development' | 'production' | undefined,
    },
    networkConfig: {
      api: APP_CONFIG.statsig.apiProxy,
      preventAllNetworkTraffic: APP_CONFIG.api.mock || APP_CONFIG.statsig.clientSideID === 'client-key',
    },
    overrideAdapter: new StatsigOverrideAdaptor(),
  },
);

export const useStatsigOverrideAdaptor = (): StatsigOverrideAdaptor => {
  const { client } = useStatsigClient();

  return client.getContext().options.overrideAdapter as StatsigOverrideAdaptor;
};
