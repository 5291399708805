import {
  ComponentConfigurationDTO,
  ComponentSiteFilterType,
  PortalComponentType,
  SiteDTO,
} from '@audioeye/mono-client';
import { useFeatureGate } from '@statsig/react-bindings';
import { useShouldUseRulesAutoRems } from 'client-pages/admin/rules/authoring/useShouldUseRulesAutoRems';
import { useAppUser } from 'state/appUser/useAppUser';
import { useGetAllSiteExpertAudits } from 'state/expertAudit/expertAuditQueries';
import { Gate } from 'types/util';

import { isInactive } from '../services/util/site/siteUtils';
import { isSubscriptionCancelled } from '../services/util/subscription/subscriptionUtils';
import { useGetSubscriptionById } from '../state/subscription/subscriptionQueries';

export const useFilterComponentsByState = (
  components: ComponentConfigurationDTO[],
  site?: SiteDTO,
): ComponentConfigurationDTO[] => {
  const { isEnterpriseUser } = useAppUser();
  const { data: subscription } = useGetSubscriptionById(site?.internalSubscriptionId ?? '');
  const isSiteInactive = isInactive(site ?? undefined);
  const isSiteCancelled = isSubscriptionCancelled(subscription);
  const isSiteCodeDetected = site?.jsInstalled;
  const { data: expertAuditData, isPending: isExpertAuditPending } = useGetAllSiteExpertAudits(site?.id ?? '');
  const hasExpertAuditData = !!expertAuditData && expertAuditData?.length > 0 && !isExpertAuditPending;
  const { value: shouldShowLegalTemplates } = useFeatureGate(Gate.ShouldShowLegalTemplates);
  const { value: shouldShowLegalHelpChanges } = useFeatureGate(Gate.ShouldShowLegalHelpChanges);
  const { data: isOnRulesBasedAutoRems } = useShouldUseRulesAutoRems({ siteOrSiteGroupId: site?.id ?? '' });

  return components.filter(({ componentFilter, type }) => {
    if (!componentFilter) {
      return true;
    }
    // Check to remove the Documentation tabs from the nav bar
    // we need to exclude the STAR Plan, A11y Statement, and Trusted Certification pages from being in the nav bar
    // These tabs have to be in the Presentation Config for the new Documentation page to render them
    // and so they can be controlled via the gate
    // However, when the gate is on, we don't want them to show in the nav bar, thus we filter them out here.
    switch (type) {
      case PortalComponentType.STAR_PLAN:
      case PortalComponentType.TRUSTED_CERTIFICATION: {
        return false;
      }
      case PortalComponentType.ACCESSIBILITY_STATEMENT: {
        if (shouldShowLegalHelpChanges) {
          return true;
        }
        return false;
      }
      case PortalComponentType.DOCUMENTATION: {
        // First check this gate to see if we should show the Documentation/Legal Templates tab at all
        // should only show if:
        // - shouldShowLegalTemplates is true
        // OR
        // - shouldShowLegalHelpChanges is true
        const shouldShowLegalTemplatesTab = shouldShowLegalTemplates || shouldShowLegalHelpChanges;

        if (!shouldShowLegalTemplatesTab) {
          return false;
        }
        // if the three tabs are not in the components config, we don't want to show the Documentation tab either!
        const requiredTypes = [
          PortalComponentType.STAR_PLAN,
          PortalComponentType.ACCESSIBILITY_STATEMENT,
          PortalComponentType.TRUSTED_CERTIFICATION,
        ];

        const hasRequiredComponent = components.some((component) =>
          requiredTypes.some((requiredType) => requiredType === component.type),
        );

        if (!hasRequiredComponent) {
          return false;
        }
        break;
      }
      default: {
        break;
      }
    }

    if (!site) {
      return true;
    }

    // Check the site filters
    const { siteFilters } = componentFilter;
    const doAnySiteFiltersFail = (siteFilters ?? []).some((siteFilter) => {
      let result: boolean | undefined;
      switch (siteFilter.type) {
        case ComponentSiteFilterType.IS_INACTIVE: {
          result = isSiteInactive;
          break;
        }
        case ComponentSiteFilterType.IS_FREE_OR_LAPSED_OR_CANCELLED: {
          result = isSiteCancelled;
          break;
        }
        case ComponentSiteFilterType.IS_ENTERPRISE_SITE: {
          result = isEnterpriseUser;
          break;
        }
        case ComponentSiteFilterType.IS_SITE_CODE_DETECTED: {
          result = isSiteCodeDetected;
          break;
        }
        case ComponentSiteFilterType.HAS_EXPERT_AUDIT_DATA: {
          result = hasExpertAuditData;
          break;
        }
        case ComponentSiteFilterType.IS_ON_RULES_BASED_AUTO_REMS: {
          result = isOnRulesBasedAutoRems;
          break;
        }
        default: {
          const _exhaustiveCheck: never = siteFilter.type;
          return _exhaustiveCheck;
        }
      }
      if (siteFilter.invertResult === true) {
        result = !result;
      }
      // We are using Array.prototype.some but are looking for anything that *fails*
      return !result;
    });
    return !doAnySiteFiltersFail;
  });
};
