import { DynamicConfig, Experiment, FeatureGate, Layer, OverrideAdapter, StatsigUser } from '@statsig/client-core';
import { storage } from 'services/storage';

import { StatsigOverrideStore } from './StatsigOverrideStore';

const LOCAL_OVERRIDE_REASON = 'LocalOverride';

const loadGateOverridesFromPlaywright = () =>
  typeof window !== 'undefined' && process.env.GATSBY_ACTIVE_ENV === 'e2e'
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__playwrightGateOverrides
    : undefined;

function _makeEmptyStore(): StatsigOverrideStore {
  return {
    gate: loadGateOverridesFromPlaywright() ?? {},
    dynamicConfig: {},
    experiment: {},
    layer: {},
  };
}

export class StatsigOverrideAdaptor implements OverrideAdapter {
  private _overrides: StatsigOverrideStore;

  constructor() {
    this._overrides = storage.local.get('statsig_overrides') ?? _makeEmptyStore();
  }

  overrideGate(name: string, value: boolean): void {
    this._overrides.gate[name] = value;
    storage.local.set('statsig_overrides', this._overrides);
  }

  removeOverride(name: string): void {
    delete this._overrides.gate[name];
    storage.local.set('statsig_overrides', this._overrides);
  }

  getGateOverride(current: FeatureGate, _user: StatsigUser): FeatureGate | null {
    const overridden = this._overrides.gate[current.name];
    if (overridden == null) {
      return null;
    }

    return {
      ...current,
      value: overridden,
      details: { ...current.details, reason: LOCAL_OVERRIDE_REASON },
    };
  }

  overrideDynamicConfig(name: string, value: Record<string, unknown>): void {
    this._overrides.dynamicConfig[name] = value;
    storage.local.set('statsig_overrides', this._overrides);
  }

  getDynamicConfigOverride(current: DynamicConfig, _user: StatsigUser): DynamicConfig | null {
    return this._getConfigOverride(current, this._overrides.dynamicConfig);
  }

  overrideExperiment(name: string, value: Record<string, unknown>): void {
    this._overrides.experiment[name] = value;
    storage.local.set('statsig_overrides', this._overrides);
  }

  getExperimentOverride(current: Experiment, _user: StatsigUser): Experiment | null {
    return this._getConfigOverride(current, this._overrides.experiment);
  }

  overrideLayer(name: string, value: Record<string, unknown>): void {
    this._overrides.layer[name] = value;
    storage.local.set('statsig_overrides', this._overrides);
  }

  getAllOverrides(): StatsigOverrideStore {
    return JSON.parse(JSON.stringify(this._overrides)) as StatsigOverrideStore;
  }

  removeAllOverrides(): void {
    this._overrides = _makeEmptyStore();
    storage.local.set('statsig_overrides', this._overrides);
  }

  getLayerOverride(current: Layer, _user: StatsigUser): Layer | null {
    const overridden = this._overrides.layer[current.name];
    if (overridden == null) {
      return null;
    }

    return {
      ...current,
      __value: overridden,
      details: { ...current.details, reason: LOCAL_OVERRIDE_REASON },
    };
  }

  private _getConfigOverride<T extends Experiment | DynamicConfig>(
    current: T,
    lookup: Record<string, Record<string, unknown>>,
  ): T | null {
    const overridden = lookup[current.name];
    if (overridden == null) {
      return null;
    }

    return {
      ...current,
      value: overridden,
      details: { ...current.details, reason: LOCAL_OVERRIDE_REASON },
    };
  }
}
