import {
  GetPrincipalPolicyAndRolesResponseDTO,
  MutatePolicyDTO,
  QueryPolicyPaginateEntitiesDTO,
  RawPolicyPaginatedResponseDTO,
  ValidateResourcesDTO,
} from '@audioeye/auth-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  addResourcePolicy,
  getPrincipalPolicyAndRoles,
  getRawPolicies,
  removeResourcePolicyById,
  validateResources,
} from '../services/api/policy.api';

enum PolicyCacheKeys {
  AllRawPolicies = 'all_raw_policies',
  Validate = 'validate',
  PolicyAndRoles = 'policy_and_roles',
}

export const useGetRawPolicies = (
  dto: QueryPolicyPaginateEntitiesDTO,
): UseInfiniteQueryResult<InfiniteData<RawPolicyPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [PolicyCacheKeys.AllRawPolicies, dto],
    queryFn: ({ pageParam }) => getRawPolicies({ ...dto, cursor: pageParam == null ? undefined : pageParam }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
  });

export const useGetPolicyAndRolesForPrincipal = (
  principalAeyeId: string | undefined,
): UseQueryResult<GetPrincipalPolicyAndRolesResponseDTO, AxiosError> =>
  useQuery({
    queryKey: [PolicyCacheKeys.PolicyAndRoles, principalAeyeId],
    queryFn: () => getPrincipalPolicyAndRoles(principalAeyeId || ''),
    enabled: !!principalAeyeId,
  });

export const useValidateResources = (dto: ValidateResourcesDTO): UseQueryResult<boolean[], AxiosError> =>
  useQuery({
    queryKey: [PolicyCacheKeys.Validate, dto],
    queryFn: () => validateResources(dto),
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled:
      dto.actorAeyeId != null &&
      dto.actorAeyeId !== '' &&
      dto.resourceType != null &&
      dto.resourceType !== '' &&
      dto.resourceKeys.length > 0,
  });

export const useAddResourcePolicy = (): UseMutationResult<void, AxiosError, MutatePolicyDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => addResourcePolicy(dto),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PolicyCacheKeys.AllRawPolicies] });
    },
  });
};

export const useRemoveResourcePolicyById = (): UseMutationResult<void, AxiosError, number> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (policyId: number) => removeResourcePolicyById(policyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PolicyCacheKeys.AllRawPolicies] });
    },
  });
};
