import { AeyeId, buildAeyeId, parseAeyeId } from '@audioeye/aeye_id';
import { PolicyAction } from '@audioeye/auth-client';
import { filterFalsyValues } from '@audioeye/common';
import { UserDTO } from '@audioeye/mono-client';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  AuthResourceType,
  PolicyResourceSystem,
  PolicyResourceType,
} from '../client-pages/admin/policy/POLICY_RESOURCE_MAPPING';
import { useGetAppUser } from '../state/appUser/appUserQueries';
import { useAuth } from '../state/auth/useAuth';
import { useValidateResources } from '../state/policyQueries';
import { AUDIOEYE_REALM_KEY } from '../util/constants';

export const getUserAeyeId = (user: UserDTO | undefined): AeyeId | undefined => {
  if (!user) {
    return undefined;
  }

  return buildAeyeId({
    compartmentKey: user.accountId,
    realmKey: AUDIOEYE_REALM_KEY,
    resourceKey: user.id,
    resourceType: AuthResourceType.PrincipalEndUser,
    system: 'auth',
  });
};

export const useGetAppUserAeyeId = (): UseQueryResult<AeyeId, Error> => {
  const { data: appUser, ...otherProps } = useGetAppUser();

  return {
    ...otherProps,
    data: getUserAeyeId(appUser),
  } as any as UseQueryResult<AeyeId, Error>;
};

export type UseUserCanForResourceResult = UseQueryResult<boolean, AxiosError>;

type UserCanForAeyeIdPayload = {
  aeyeId: AeyeId | string;
  action?: PolicyAction;
  shouldCheckAgainstLoggedInUser?: boolean;
};
export function useUserCanForAeyeId(aeyeId: string | AeyeId, action?: PolicyAction): UseUserCanForResourceResult;
export function useUserCanForAeyeId(payload: UserCanForAeyeIdPayload): UseUserCanForResourceResult;
export function useUserCanForAeyeId(
  params: UserCanForAeyeIdPayload | string | AeyeId,
  inputAction: PolicyAction = PolicyAction.READ,
): UseUserCanForResourceResult {
  // Cast this since an AeyeId is actually just a string
  const aeyeIdOrObject = params as string | UserCanForAeyeIdPayload;
  let shouldCheckAgainstLoggedInUser = false;
  let action = inputAction;
  let aeyeId;
  if (typeof aeyeIdOrObject === 'object') {
    shouldCheckAgainstLoggedInUser = aeyeIdOrObject.shouldCheckAgainstLoggedInUser ?? false;
    action = aeyeIdOrObject.action ?? action;
    aeyeId = aeyeIdOrObject.aeyeId;
  } else {
    aeyeId = aeyeIdOrObject;
  }
  const { data: appUser, isLoading: isAppUserLoading } = useGetAppUser();
  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useAuth();

  const actorAeyeId = getUserAeyeId(shouldCheckAgainstLoggedInUser ? loggedInUser : appUser);

  const { compartmentKey, system, ...parsedValues } = parseAeyeId(aeyeId);
  const { data, ...result } = useValidateResources({
    actorAeyeId: actorAeyeId?.toString() ?? '',
    action,
    ...parsedValues,
    compartmentKey: compartmentKey ?? '',
    resourceKeys: [parsedValues.resourceKey].filter(filterFalsyValues),
    resourceSystem: system ?? '',
  });

  return {
    ...result,
    data: data?.[0],
    isLoading: result.isLoading || isAppUserLoading || isLoggedInUserLoading,
  } as any as UseUserCanForResourceResult;
}

export const useUserCanForResource = ({
  action,
  resourceType,
  dto,
  resourceSystem = 'mono',
}: {
  action: PolicyAction;
  resourceType: PolicyResourceType;
  dto: { id: string | number; accountId?: string; subBrandId?: string; compartmentId?: string | null } | undefined;
  resourceSystem?: PolicyResourceSystem;
}): UseUserCanForResourceResult => {
  const { data: actorAeyeId } = useGetAppUserAeyeId();
  const { data, ...result } = useValidateResources({
    actorAeyeId: actorAeyeId?.toString() ?? '',
    action,
    compartmentKey: dto?.subBrandId || dto?.accountId || dto?.compartmentId || '',
    resourceSystem,
    resourceType,
    resourceKeys: [dto?.id.toString()].filter(filterFalsyValues),
  });

  return {
    data: data?.[0] ?? false,
    ...result,
  } as any as UseUserCanForResourceResult;
};

export const useUserCanPaginateResource = ({
  resourceType,
  compartmentId,
  resourceSystem = 'mono',
}: {
  resourceType: PolicyResourceType;
  compartmentId: string;
  resourceSystem?: PolicyResourceSystem;
}) =>
  useUserCanForResource({
    action: PolicyAction.READ,
    resourceType,
    dto: {
      id: '*',
      compartmentId,
    },
    resourceSystem,
  });

const AGGREGATE_COUNT_RESOURCE_KEY = 'AGGREGATE__COUNT';
export const useUserCanCountResource = ({
  resourceType,
  compartmentId,
  resourceSystem = 'mono',
}: {
  resourceType: PolicyResourceType;
  compartmentId: string;
  resourceSystem?: PolicyResourceSystem;
}) =>
  useUserCanForResource({
    action: PolicyAction.READ,
    resourceType,
    dto: {
      id: AGGREGATE_COUNT_RESOURCE_KEY,
      compartmentId,
    },
    resourceSystem,
  });

export const useUserCanForManyResources = ({
  action,
  compartmentKey,
  resourceType,
  resourceKeys,
  resourceSystem = 'mono',
}: {
  action: PolicyAction;
  compartmentKey: string;
  resourceType: PolicyResourceType;
  resourceKeys: string[];
  resourceSystem?: PolicyResourceSystem;
}): UseQueryResult<boolean[], AxiosError> => {
  const { data: actorAeyeId } = useGetAppUserAeyeId();

  return useValidateResources({
    actorAeyeId: actorAeyeId?.toString() ?? '',
    action,
    resourceSystem,
    compartmentKey,
    resourceType,
    resourceKeys,
  });
};
