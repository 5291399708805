import {
  MutateSSOConfigurationDTO,
  QuerySSOConfigurationPaginateEntitiesDTO,
  SSOConfigurationDTO,
  SSOConfigurationPaginatedResponseDTO,
  SSOConfigurationPublicDTO,
} from '@audioeye/auth-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  createSSOConfiguration,
  deleteSSOConfiguration,
  getPublicSSOConfigurationById,
  getSSOConfigurationById,
  getSSOConfigurations,
  getSSOConfigurationsByDomain,
  updateSSOConfiguration,
} from '../services/api/sso.api';
import { VENASTA_SSO_CONFIG_IDS } from '../util/VENDASTA_SSO_CONFIG_IDS';

enum SSOCacheKeys {
  AllConfigurations = 'query_sso_configurations',
  ByID = 'by_id',
  PublicByID = 'public_by_id',
  ByDomain = 'by_domain',
}

export const useGetSSOConfigurations = (
  dto: QuerySSOConfigurationPaginateEntitiesDTO,
  isQueryEnabled: boolean = true,
): UseInfiniteQueryResult<InfiniteData<SSOConfigurationPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SSOCacheKeys.AllConfigurations, dto],
    queryFn: ({ pageParam }) => getSSOConfigurations({ ...dto, cursor: pageParam == null ? undefined : pageParam }),
    enabled: isQueryEnabled,
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    initialPageParam: '',
  });

export const useGetSSOConfigurationById = (
  ssoConfigurationId: string | null | undefined,
): UseQueryResult<SSOConfigurationDTO, AxiosError> =>
  useQuery({
    queryKey: [SSOCacheKeys.ByID, ssoConfigurationId],
    queryFn: () => getSSOConfigurationById(ssoConfigurationId || ''),
    enabled: !!ssoConfigurationId && !VENASTA_SSO_CONFIG_IDS.includes(ssoConfigurationId),
  });

export const useGetPublicSSOConfigurationById = (
  ssoConfigurationId: string | null | undefined,
): UseQueryResult<SSOConfigurationPublicDTO, AxiosError> =>
  useQuery({
    queryKey: [SSOCacheKeys.PublicByID, ssoConfigurationId],
    queryFn: () => getPublicSSOConfigurationById(ssoConfigurationId || ''),
    enabled: !!ssoConfigurationId && !VENASTA_SSO_CONFIG_IDS.includes(ssoConfigurationId),
  });

export const useGetSSOConfigurationsByDomain = (
  domain: string | null | undefined,
): UseQueryResult<SSOConfigurationPublicDTO[], AxiosError> =>
  useQuery({
    queryKey: [SSOCacheKeys.ByDomain, domain],
    queryFn: () => getSSOConfigurationsByDomain(domain || ''),
    enabled: !!domain,
    retry: 0,
  });

export const useCreateSSOConfiguration = (): UseMutationResult<
  SSOConfigurationDTO,
  AxiosError,
  MutateSSOConfigurationDTO
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSSOConfiguration,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SSOCacheKeys.AllConfigurations] });
    },
  });
};

export const useUpdateSSOConfiguration = (): UseMutationResult<
  SSOConfigurationDTO,
  AxiosError,
  MutateSSOConfigurationDTO & { id: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) => updateSSOConfiguration(id, dto),
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: [SSOCacheKeys.AllConfigurations] });
      await queryClient.invalidateQueries({ queryKey: [SSOCacheKeys.ByID, id] });
    },
  });
};

export const useDeleteSSOConfiguration = (): UseMutationResult<void, AxiosError, { ssoConfigurationId: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ ssoConfigurationId }) => deleteSSOConfiguration(ssoConfigurationId),
    onSuccess: async (_, { ssoConfigurationId }) => {
      await queryClient.invalidateQueries({ queryKey: [SSOCacheKeys.AllConfigurations] });
      await queryClient.invalidateQueries({ queryKey: [SSOCacheKeys.ByID, ssoConfigurationId] });
    },
  });
};
