/**
 * Basic url helpers
 */
export const getQueryParam = (paramName: string) => {
  const queryParams = new URLSearchParams((typeof window !== 'undefined' && window.location.search) || '');
  return queryParams.get(paramName);
};

export const getQueryParams = (queries?: string) => {
  const params: Record<string, string> = {};
  const query = new URLSearchParams(queries || (typeof window !== 'undefined' && window.location.search) || '');
  query.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

export const queryParams = (params: Record<string, string | number | undefined | Array<string | number>>) => {
  const filteredParams = Object.keys(params)
    .sort()
    .reduce((filtered, key) => {
      const value = params[key];
      if (value == null) {
        return filtered;
      }
      if (value === '') {
        return filtered;
      }

      if (Array.isArray(value)) {
        if (value.length === 0) {
          return filtered;
        }

        return filtered.concat(value.map((v) => [key, v.toString()]));
      }

      return [...filtered, [key, value.toString()]];
    }, [] as string[][]);

  const renderedParams = new URLSearchParams(filteredParams).toString();
  if (!renderedParams) {
    return '';
  }
  return `?${renderedParams}`;
};

const addQueryStrings = (url: string, qs: { [key: string]: string | undefined }[] = []) => {
  const queryStrings = qs.map(Object.entries).reduce((s, [k, v]) => (s && v ? `${s}&${k}=${v}` : s), '');

  return queryStrings ? `${url}?${queryStrings}` : url;
};

export const urlService = {
  getQueryParam,
  getQueryParams,
  queryParams,
  addQueryStrings,
};
