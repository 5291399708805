import { ApiGate } from '@audioeye/auth-common';
import { datadogRum } from '@datadog/browser-rum-slim';
import { StatsigUser } from '@statsig/js-client';
import { useStatsigClient, useStatsigUser } from '@statsig/react-bindings';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { storage } from 'services/storage';
import { useGetAccountById } from 'state/accountQueries';
import { useImpersonation } from 'state/appUser/appUserQueries';
import { useAppUser } from 'state/appUser/useAppUser';
import { useGetSingleSignOnConfigId } from 'state/auth/authQueries';
import { useGetIntegrationBySSOConfigId } from 'state/integrationQueries';

import { useAuth } from '../state/auth/useAuth';
import { useGetUISettings } from './useGetUISettings';
import { useUserCanForAeyeId } from './useUserCan';

interface Heap {
  track: (event: string, properties?: object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: object) => void;
  addEventProperties: (properties: object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

declare global {
  interface Window {
    heap: Heap;
  }
}

const STATSIG_CONTEXTUAL_ID = 'STATSIG_CONTEXTUAL_ID';

type StatsigContextualIDs = {
  siteId: string | undefined;
  subscriptionId: string | undefined;
  planId: string | undefined;
};

const INITIAL_STATSIG_CONTEXTUAL_IDS = {
  siteId: undefined,
  subscriptionId: undefined,
  planId: undefined,
};
export const useStatsigContextualIds = (): [
  StatsigContextualIDs | undefined,
  (value: Partial<StatsigContextualIDs>) => void,
] => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: [STATSIG_CONTEXTUAL_ID],
    queryFn: () => INITIAL_STATSIG_CONTEXTUAL_IDS,
    initialData: INITIAL_STATSIG_CONTEXTUAL_IDS,
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const callback = useCallback(
    (newValue: Partial<StatsigContextualIDs>) => {
      queryClient.setQueryData([STATSIG_CONTEXTUAL_ID], {
        ...data,
        ...newValue,
      });
    },
    [queryClient],
  );

  return [data, callback];
};

export const useResolveStatsigUser = (): { statsigUser: StatsigUser; isLoading: boolean } => {
  const { client } = useStatsigClient();
  const { updateUserAsync } = useStatsigUser();
  const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);
  const { isLoading: isAuthLoading, user: authUser } = useAuth();
  const { data: isSuperUser } = useUserCanForAeyeId({
    aeyeId: ApiGate.SuperUser,
    shouldCheckAgainstLoggedInUser: true,
  });
  const { data: isAdmin } = useUserCanForAeyeId({
    aeyeId: ApiGate.Admin,
    shouldCheckAgainstLoggedInUser: true,
  });
  const { user, isResellerPartner, isEnterpriseUser, isLoading: areAppUserDetailsLoading } = useAppUser();
  const { data: account, isLoading: isAccountLoading } = useGetAccountById(user?.accountId);
  const [isImpersonating] = useImpersonation();
  const singleSignOnConfigId = useGetSingleSignOnConfigId();
  const { data: integration, isLoading: isIntegrationLoading } = useGetIntegrationBySSOConfigId(
    // This will 403 if the user isn't finished logging in
    user != null ? singleSignOnConfigId : undefined,
  );
  const { data: uiSettings } = useGetUISettings();
  const [contextualIds] = useStatsigContextualIds();

  const statsigUser = useMemo(() => {
    return {
      userID: user?.id,
      custom: {
        authUserId: authUser?.id,
        isSuperUser,
        // keep this until we can verify no gates use it
        isAdmin,
        isImpersonating,
        isResellerPartner,
        isPartner: isResellerPartner,
        isEnterpriseUser,
        manualBilling: account?.isManualBilling,
        partnerFriendlyName: integration?.partnerFriendlyName?.toLowerCase(),
        product: integration?.product?.toLowerCase(),
        integrationId: integration?.id,
        accountId: user?.accountId,
        customerType: account?.customerType,
        isJavaScriptAutoInstalled: uiSettings?.isJavaScriptAutoInstalled ?? false,
        isSingleSiteOnly: uiSettings?.isSingleSiteOnly ?? false,
        isLoggedInViaSSO: singleSignOnConfigId != null,
        ...contextualIds,
      },
      customIDs: {
        accountId: authUser?.accountId ?? '',
      },
    };
  }, [
    authUser,
    user,
    isSuperUser,
    isImpersonating,
    isResellerPartner,
    isEnterpriseUser,
    account,
    integration,
    uiSettings?.isSingleSiteOnly,
    uiSettings?.isJavaScriptAutoInstalled,
    contextualIds,
  ]);

  const isBrowser = typeof window !== 'undefined';
  const heap = isBrowser ? window.heap : undefined;

  let didRunUpdates = false;
  useMemo(() => {
    didRunUpdates = true;
    setIsUpdatingUser(true);
    // eslint-disable-next-line no-void
    void updateUserAsync(statsigUser).finally(() => setIsUpdatingUser(false));
    storage.local.set('statsig_user_data', statsigUser);
    if (!user) {
      datadogRum.clearUser();
      return;
    }

    datadogRum.setUser(statsigUser);

    if (heap == null) {
      return;
    }

    heap.identify(user.id);
    heap.addUserProperties({ ...statsigUser.custom, accountId: statsigUser.customIDs.accountId });
  }, [user?.id, statsigUser, heap]);

  const isLoading =
    isAuthLoading ||
    areAppUserDetailsLoading ||
    isAccountLoading ||
    isIntegrationLoading ||
    isUpdatingUser ||
    didRunUpdates ||
    client.loadingStatus !== 'Ready';

  return {
    statsigUser,
    isLoading,
  };
};
