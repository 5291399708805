import {
  AuthenticationApi,
  AuthenticationSessionDTO,
  ForgotPasswordDTO,
  MagicLinkUserDTO,
} from '@audioeye/auth-client';
import { AuthApi, CreateUserDto, LoginDto, UserApi, UserDTO } from '@audioeye/mono-client';
import nullthrows from 'nullthrows';

import { APP_CONFIG } from '../../../config';
import { client } from '../client';
import { AuthStorage } from './AuthStorage';
import { AuthClient } from './types';

const userApi = new UserApi(undefined, APP_CONFIG.api.url, client);
const authApi = new AuthApi(undefined, APP_CONFIG.api.url, client);
const authenticationApi = new AuthenticationApi(undefined, APP_CONFIG.auth.serviceUrl, client);

export const authServiceClient = (): AuthClient => {
  /**
   * Get current user session and refresh
   */
  const getSession = async (): Promise<AuthenticationSessionDTO | null> => {
    const authSession = AuthStorage.getSession();
    if (!authSession) {
      return null;
    }

    const { data } = await authenticationApi.currentSession(authSession);
    return data;
  };

  /**
   * Register new user
   */
  const register = async (data: CreateUserDto): Promise<UserDTO> => {
    const result = await userApi.createUser(data);
    return result.data;
  };

  /**
   * Login user
   */
  const login = async ({ username, password }: LoginDto) => {
    const result = await authApi.loginUser({ username, password });
    AuthStorage.setSession(result.data);
    const session = nullthrows(await getSession());
    return session;
  };

  const logout = async (): Promise<void> => AuthStorage.clearSession();

  /**
   * Initiate forgotPassword flow
   */
  const forgotPassword = async (params: ForgotPasswordDTO): Promise<true> => {
    await authenticationApi.forgotPassword(params);
    return true;
  };

  const forgotPasswordSubmit = async (email: string, code: string, password: string): Promise<'SUCCESS'> => {
    await authenticationApi.forgotPasswordSubmit({ email, code, password });
    return 'SUCCESS';
  };

  const changePassword = async (oldPassword: string, newPassword: string): Promise<'SUCCESS'> => {
    const session = await getSession();
    if (!session) {
      throw new Error("Cannot change password when the user doesn't have a session");
    }

    await authenticationApi.changePassword({
      accessToken: session.accessToken,
      oldPassword,
      newPassword,
    });
    return 'SUCCESS';
  };

  /**
   * OAuth authorize. Redirects to Cognito hosted auth form.
   * Cognito automatically handles authorization on redirect back to app.
   */
  const oauthAuthorize = () => {
    throw new Error('Not implemented');
  };

  /**
   * Verify challenge from custom_auth flow
   */
  const verifyChallenge = async (magicLinkUserDto: MagicLinkUserDTO): Promise<void> => {
    const { data: magicLinkSession } = await authenticationApi.createSessionForMagicLinkUser(magicLinkUserDto);

    const { data: newSession } = await authenticationApi.currentSession(magicLinkSession);

    AuthStorage.setSession(newSession);
  };

  return {
    getSession,
    register,
    login,
    logout,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    oauthAuthorize,
    verifyChallenge,
  };
};
