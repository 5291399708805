import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useGetPresentationConfigById } from 'state/presentationConfigQueries';

const CACHE_KEY = 'EMULATED_PRESENTATION_CONFIG_ID';

export const useEmulatedPresentationConfigId = (): [string | undefined, (value: string | null) => void] => {
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [CACHE_KEY],
    queryFn: () => null,
    initialData: null,
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return [
    data ?? undefined,
    (newValue: string | null) => {
      queryClient.setQueryData([CACHE_KEY], newValue);
    },
  ];
};

export const useEmulatedPresentationConfig = () => {
  const [presentationConfigId] = useEmulatedPresentationConfigId();
  const { data: presentationConfig } = useGetPresentationConfigById(presentationConfigId);

  if (!presentationConfigId) {
    return undefined;
  }

  return presentationConfig;
};
