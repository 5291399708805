import React from 'react';
import { useRetrieveSession } from 'state/auth/authQueries';

export interface AuthenticatorProps {
  children?: React.ReactElement;
}

/**
 * DO NOT RUN ANY GATED CODE IN THIS COMPONENT.
 * WE INTENTIONALLY WRAP STATSIG WITH THIS COMPONENT TO REDUCE REFLOWS
 */
export const Authenticator: React.FC<AuthenticatorProps> = ({ children }) => {
  const { isLoading } = useRetrieveSession();
  // Only render after we've checked for an existing session
  // NOTE: We only do this on the client to avoid unnecessary redirects, while preserving ssr for public pages
  if (isLoading && typeof window !== 'undefined') return null;

  return children || null;
};
