import { Box, Heading, Icon, Link, Text } from '@audioeye/inclusive-ui';
import { useDynamicConfig } from '@statsig/react-bindings';
import { rem } from 'polished';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { DynamicConfig } from 'types/util';
import { DateFormat, formatDate, formatTime } from 'util/formatDate';

import * as style from '../../layouts/BasicLayout/BasicLayout.style';

type UnderConstructionTimes = {
  endTime: string;
  startTime: string;
};

const helmetTitle = <Helmet title="Site Under Construction" />;

/**
 * Page for displaying whenever the site is under construction
 * Ex: db migrations, major updates, etc.
 *
 * Date/Time is controlled via
 *  https://console.statsig.com/2wzWdy0ATLZvqO6415RgUG/dynamic_configs/under_construction_time
 * Update the start and end time there to update this page
 * The page itself will display only if the gate `should_show_construction_page` is true
 *
 */
export const SiteUnderConstructionPage: React.FC = () => {
  const { value: underConstructionTimeFrames } = useDynamicConfig(DynamicConfig.UnderConstructionTime);
  const { endTime, startTime } = underConstructionTimeFrames as UnderConstructionTimes;

  // Fallbacks in case the times are not set
  const endTimeString = endTime ? `${formatDate(endTime, DateFormat.Short)} at ${formatTime(endTime)}` : 'TBD';
  const startTimeString = startTime ? `${formatDate(startTime, DateFormat.Short)} at ${formatTime(startTime)}` : 'TBD';

  const content = useMemo(
    () => (
      <Box css={style.root}>
        <Box align="center" css={{ justifyContent: 'center' }} width="100%" pad="xlarge" gap="medium" direction="row">
          <Icon type="status-warning" size={rem(96)} />
          <Box direction="column">
            <Heading level="1">Site Under Construction</Heading>
            <Text variant="reg">
              We are currently working on this site. You can view more details{' '}
              <Link target="_blank" href="https://status.audioeye.com/">
                here.
              </Link>
            </Text>
            <Text variant="reg">
              <strong>Start Time:</strong> {startTimeString}
            </Text>
            <Text variant="reg">
              <strong>Estimated End time:</strong> {endTimeString}
            </Text>
          </Box>
        </Box>
      </Box>
    ),
    [endTimeString, startTimeString],
  );

  return (
    <>
      {helmetTitle}
      {content}
    </>
  );
};
