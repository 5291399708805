import { APP_CONFIG } from 'config';
import React from 'react';
import Helmet from 'react-helmet';

export function ChargeBeeScript() {
  const { site } = APP_CONFIG.chargebee;

  return (
    <Helmet>
      <script type="text/javascript" src="https://js.chargebee.com/v2/chargebee.js" data-cb-site={site} />
    </Helmet>
  );
}
