import React, { Dispatch, PropsWithChildren, useContext, useMemo, useReducer } from 'react';

import { NotificationMessage, NotificationsState } from '../../types/notifications';

const initialState: NotificationsState = {
  popping: false,
  messages: [],
};

export enum NotificationActionTypes {
  ADD = '@notifications/ADD',
  POP_START = '@notifications/POP_START',
  POP_COMPLETE = '@notifications/POP_COMPLETE',
  POP_FAILURE = '@notifications/POP_FAILURE',
  CLEAR_ALL = '@notifications/CLEAR_ALL',
}

type NotificationAction =
  | {
      type: NotificationActionTypes.ADD;
      payload: NotificationMessage;
    }
  | {
      type:
        | NotificationActionTypes.POP_START
        | NotificationActionTypes.POP_COMPLETE
        | NotificationActionTypes.POP_FAILURE
        | NotificationActionTypes.CLEAR_ALL;
    };

export const NotificationContext = React.createContext<[NotificationsState, Dispatch<NotificationAction>]>([] as any);

function reducer(state: NotificationsState, action: NotificationAction): NotificationsState {
  switch (action.type) {
    case NotificationActionTypes.ADD: {
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    }
    case NotificationActionTypes.POP_START: {
      return {
        ...state,
        popping: true,
      };
    }
    case NotificationActionTypes.POP_COMPLETE: {
      return {
        ...state,
        popping: false,
        messages: state.messages.slice(1),
      };
    }
    case NotificationActionTypes.POP_FAILURE: {
      return {
        ...state,
        popping: false,
      };
    }
    case NotificationActionTypes.CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export const useNotificationState = (): [NotificationsState, Dispatch<NotificationAction>] =>
  useContext(NotificationContext);

export const NotificationStoreProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo<[NotificationsState, Dispatch<NotificationAction>]>(() => [state, dispatch], [state]);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
