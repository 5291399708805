import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';
import React, { PropsWithChildren, useCallback } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { track } from 'services/analytics/analytics';
import { AnalyticsEvent } from 'types/analytics';

const FallbackComponent: React.FC = () => <h1>something went wrong</h1>;

export const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  const onError = useCallback(
    (
      error: Error,
      info: {
        componentStack: string;
      },
    ) => {
      track(AnalyticsEvent.CLIENT_EXCEPTION, {
        label: error.message,
        full_exception: error,
      });

      datadogLogs.logger.error(error.message, error);

      error.name = 'ReactRenderingError';
      error.stack = info.componentStack;
      datadogRum.addError(error);
    },
    [],
  );

  return (
    <ReactErrorBoundary fallback={<FallbackComponent />} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};
