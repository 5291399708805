import React, { useCallback, useContext, useEffect, useState } from 'react';
import { storage } from 'services/storage';
import { ReportingVersionType, useGetResolvedReportingConfigForSite } from 'state/rules/rulesQueries';

const LOCAL_STORAGE_KEY = 'reporting_version';
const REPORT_VERION_EVENT_NAME = 'audioeye.set_reporting_version';
type EventData = { reportingVersionType: ReportingVersionType | null };

type ReportingVersionContextValue = { reportingVersionType: ReportingVersionType };

const ReportingVersionContext = React.createContext<ReportingVersionContextValue>({
  reportingVersionType: ReportingVersionType.Release,
});

export const ReportingVersionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [reportingVersionContextValue, setReportingVersionType] = useState<ReportingVersionContextValue>({
    reportingVersionType:
      (storage.session.get(LOCAL_STORAGE_KEY) as ReportingVersionType) ?? ReportingVersionType.Release,
  });

  useEffect(() => {
    const handler = ({ detail: { reportingVersionType: newReportingVersionType } }: CustomEvent<EventData>): void => {
      // so this is going to be inefficient, but since it's temporary and is only manually invoked from
      // console, that's OK.
      if (newReportingVersionType != null) {
        storage.session.set(LOCAL_STORAGE_KEY, newReportingVersionType);
      } else {
        storage.session.remove(LOCAL_STORAGE_KEY);
      }
      setReportingVersionType({ reportingVersionType: newReportingVersionType ?? ReportingVersionType.Release });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.addEventListener(REPORT_VERION_EVENT_NAME, handler as any);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.removeEventListener(REPORT_VERION_EVENT_NAME, handler as any);
    };
  }, []);

  return (
    <ReportingVersionContext.Provider value={reportingVersionContextValue}>{children}</ReportingVersionContext.Provider>
  );
};

export const useReportingVersionType = (): ReportingVersionType => {
  const reportingVersionContextValue = useContext(ReportingVersionContext);
  return reportingVersionContextValue.reportingVersionType;
};

export function useSetReportingVersionType(): (type: ReportingVersionType) => void {
  return useCallback(
    (type) =>
      window.dispatchEvent(
        new CustomEvent('audioeye.set_reporting_version', {
          detail: { reportingVersionType: type },
        }),
      ),
    [],
  );
}

export function useReportingVersion({
  siteId,
  reportShareId,
}: {
  siteId: string | undefined;
  reportShareId: string | undefined;
}): { data: string | undefined; isPending: boolean } {
  const { reportingVersionType: type } = useContext(ReportingVersionContext);
  const { data, isPending } = useGetResolvedReportingConfigForSite({
    siteId,
    reportShareId,
    select: (resolved) =>
      (resolved[type === ReportingVersionType.Release ? 'releaseVersion' : 'shadowVersion'] ??
        resolved.releaseVersion) as string,
  });

  return { data, isPending };
}

export function useRulesVersion({
  siteId,
  reportShareId,
}: {
  siteId: string | undefined;
  reportShareId: string | undefined;
}): string | undefined {
  const { reportingVersionType: type } = useContext(ReportingVersionContext);
  return useGetResolvedReportingConfigForSite({
    siteId,
    reportShareId,
    select: (resolved) =>
      (resolved[type === ReportingVersionType.Release ? 'releaseRulesVersion' : 'shadowRulesVersion'] ??
        resolved.releaseRulesVersion) as string,
  })?.data;
}
