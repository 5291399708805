import { UserDTO } from '@audioeye/mono-client';
import { useEffect } from 'react';

import { useGetAppUserId, useSetAppUserId } from '../appUser/appUserQueries';
import { useGetUserById } from '../users/userQueries';
import { useRetrieveSession } from './authQueries';

/**
 * Auth hook helper
 */
export const useAuth = (): {
  user: UserDTO | undefined;
  isLoggedIn: boolean;
  isLoading: boolean;
} => {
  const { data: auth, isFetching: isAuthFetching } = useRetrieveSession();
  const { data: user, isLoading: isUserLoading } = useGetUserById(auth?.userId);
  const appUserId = useGetAppUserId();
  const setAppUserId = useSetAppUserId();

  const isLoggedIn = auth?.accessToken != null;

  useEffect(() => {
    if (isLoggedIn && !appUserId && auth) {
      setAppUserId(auth.userId);
    } else if (!isLoggedIn && appUserId) {
      setAppUserId(undefined);
    }
  }, [isLoggedIn, appUserId, auth]);

  return {
    user,
    isLoggedIn: !!auth?.accessToken,
    isLoading: isAuthFetching || isUserLoading,
  };
};

export type UseAuthReturnValue = ReturnType<typeof useAuth>;
