import { alertClasses, avatarClasses, tabClasses, tabListClasses } from '@mui/joy';
import { extendTheme, type PaletteRange } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    info: true;
  }

  interface Palette {
    // this will make the node `info` configurable in `extendTheme`
    // and add `info` to the theme's palette.
    info: PaletteRange;
  }
}

export const springtimeJoyTheme = extendTheme({
  components: {
    JoyLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 'bold',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    JoyCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: '0.75rem',
          borderColor: 'transparent',
          boxShadow: '0px 4px 16px rgba(38, 30, 61, 0.08)',
        },
      },
    },
    JoyTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${tabListClasses.root}`]: {
            gap: '1rem',
            height: '3.5rem',
          },
          [`& .${tabClasses.root}`]: {
            borderRadius: '8px 8px 0 0',
            [`&.${tabClasses.selected}`]: {
              [`&.${tabClasses.colorPrimary}`]: {
                [`&.${tabClasses.variantPlain}`]: {
                  backgroundColor: theme.palette.primary['200'],
                  color: theme.palette.primary['800'],
                },
              },
            },
            [`&:not(.${tabClasses.selected},[aria-selected="true"]):hover`]: {
              [`&.${tabClasses.colorPrimary}`]: {
                [`&.${tabClasses.variantPlain}`]: {
                  color: theme.palette.primary['700'],
                },
              },
            },
          },
        }),
      },
    },
    JoyAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${alertClasses.root}`]: {
            [`& .${avatarClasses.root}`]: {
              [`&.${avatarClasses.variantSolid}`]: {
                [`&.${avatarClasses.colorPrimary}`]: {
                  backgroundColor: theme.palette.primary['200'],
                  color: theme.palette.primary['700'],
                },
                [`&.${avatarClasses.colorNeutral}`]: {
                  backgroundColor: theme.palette.neutral['200'],
                  color: theme.palette.neutral['700'],
                },
                [`&.${avatarClasses.colorDanger}`]: {
                  backgroundColor: theme.palette.danger['200'],
                  color: theme.palette.danger['700'],
                },
                [`&.${avatarClasses.colorSuccess}`]: {
                  backgroundColor: theme.palette.success['200'],
                  color: theme.palette.success['700'],
                },
                [`&.${avatarClasses.colorWarning}`]: {
                  backgroundColor: theme.palette.warning['200'],
                  color: theme.palette.warning['700'],
                },
                [`&.MuiAvatar-colorInfo`]: {
                  backgroundColor: theme.palette.info['200'],
                  color: theme.palette.info['700'],
                },
              },
            },
          },
        }),
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#DFF7FB',
          '100': '#C1F2F9',
          '200': '#95E9F4',
          '300': '#6DDEED',
          '400': '#28CBE1',
          '500': '#0AADC3',
          '600': '#09819B',
          '700': '#056B82',
          '800': '#034D5E',
          '900': '#003B48',
        },
        info: {
          '50': '#F0F5FF',
          '100': '#DBE8FF',
          '200': '#B8D2FF',
          '300': '#94BBFF',
          '400': '#6B9FFF',
          '500': '#356EE9',
          '600': '#1B4DC0',
          '700': '#0F328A',
          '800': '#012260',
          '900': '#011737',
          // add more variations of Joy global variant tokens below as necessary
          // see https://mui.com/joy-ui/customization/theme-colors/#global-variant-tokens
          // amd https://mui.com/joy-ui/customization/theme-colors/#adding-new-palettes
          // plain variant
          plainColor: 'var(--joy-palette-info-500)',
          plainHoverBg: 'var(--joy-palette-info-100)',
          plainActiveBg: 'var(--joy-palette-info-200)',
          plainDisabledColor: 'var(--joy-palette-neutral-400)',
          // outlined variant
          outlinedColor: 'var(--joy-palette-info-500)',
          outlinedBorder: 'var(--joy-palette-info-300)',
          outlinedHoverBg: 'var(--joy-palette-info-100)',
          outlinedActiveBg: 'var(--joy-palette-info-200)',
          outlinedDisabledColor: 'var(--joy-palette-info-400)',
          outlinedDisabledBorder: 'var(--joy-palette-info-200)',
          // soft variant
          softColor: 'var(--joy-palette-info-700)',
          softBg: 'var(--joy-palette-info-100)',
          softHoverBg: 'var(--joy-palette-info-200)',
          softActiveBg: 'var(--joy-palette-info-300)',
          softDisabledColor: 'var(--joy-palette-info-400)',
          softDisabledBg: 'var(--joy-palette-info-50)',
          // solid variant
          solidColor: 'var(--joy-palette-common-white)',
          solidBg: 'var(--joy-palette-info-500)',
          solidHoverBg: 'var(--joy-palette-info-600)',
          solidActiveBg: 'var(--joy-palette-info-700)',
          solidDisabledColor: 'var(--joy-palette-info-400)',
          solidDisabledBg: 'var(--joy-palette-info-100)',
        },
      },
    },
  },
});
