/**
 * Simple sessionStorage wrapper to abstract json conversion
 * and maintain type safety.
 */

// Define any additional LS keys/types here
export interface SessionStorageConfig {
  // Set storage keys and associated types here:
  dismissedSiteNotifications: { [siteId: string]: boolean };
  dismissedDashboardModals: { [siteId: string]: boolean };
  reporting_version: string;
  redirect_url_after_login: string;
}

/**
 * Get session storage value
 */
export const get = <K extends keyof SessionStorageConfig>(key: K, defaultValue?: SessionStorageConfig[typeof key]) => {
  const data = typeof window !== 'undefined' && window.sessionStorage.getItem(key);

  if (data && data !== 'undefined') {
    const value = JSON.parse(data) as SessionStorageConfig[typeof key];

    return value;
  }

  return defaultValue;
};

/**
 * Set sessionStorage value
 */
export const set = (key: keyof SessionStorageConfig, data: SessionStorageConfig[typeof key]) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, JSON.stringify(data));
  }
};

/**
 * Remove session storage value
 */
export const remove = (key: keyof SessionStorageConfig) =>
  typeof window !== 'undefined' && window.sessionStorage.removeItem(key);

export const clear = () => typeof window !== 'undefined' && window.sessionStorage.clear();
