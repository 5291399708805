import {
  MutateSiteCreationDefaultsDto,
  QuerySiteCreationDefaultPaginateEntitiesDTO,
  SiteConfig,
  SiteCreationDefaultDTO,
  SiteCreationDefaultPaginatedResponseDTO,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  createSiteCreationDefault,
  deleteSiteCreationDefault,
  getSiteCreationDefaultById,
  getSiteCreationDefaults,
  updateSiteCreationDefault,
  updateSiteCreationDefaultSiteConfig,
} from '../../services/api/siteCreationDefault.api';
import { SiteGroupCacheKeys } from '../siteGroups/siteGroupQueries';

enum SiteCreationDefaultCacheKeys {
  SiteCreationDefaults = 'site_creation_defaults',
  SiteCreationDefault = 'site_creation_default',
}

export const useGetSiteCreationDefaults = (
  dto: QuerySiteCreationDefaultPaginateEntitiesDTO,
): UseInfiniteQueryResult<InfiniteData<SiteCreationDefaultPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults, dto],
    queryFn: () => getSiteCreationDefaults(dto),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    initialPageParam: '',
  });

export const useGetSiteCreationDefaultById = (
  siteCreationDefaultId: string | null | undefined,
): UseQueryResult<SiteCreationDefaultDTO, AxiosError> =>
  useQuery({
    queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, siteCreationDefaultId],
    queryFn: () => getSiteCreationDefaultById(siteCreationDefaultId || ''),
    enabled: !!siteCreationDefaultId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

export const useCreateSiteCreationDefault = (): UseMutationResult<
  SiteCreationDefaultDTO,
  Error,
  MutateSiteCreationDefaultsDto
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSiteCreationDefault,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};

export const useUpdateSiteCreationDefault = (): UseMutationResult<
  SiteCreationDefaultDTO,
  Error,
  MutateSiteCreationDefaultsDto & { id: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) => updateSiteCreationDefault(id, dto),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, params.id] });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};

export const useUpdateSiteCreationDefaultSiteConfig = (): UseMutationResult<
  SiteCreationDefaultDTO,
  Error,
  { siteCreationDefaultId: string; siteConfig: SiteConfig }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteCreationDefaultId, siteConfig }) =>
      updateSiteCreationDefaultSiteConfig(siteCreationDefaultId, siteConfig),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({
        queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, params.siteCreationDefaultId],
      });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};

type DeleteSiteCreationDefaultParams = {
  siteCreationDefaultId: string;
};

export const useDeleteSiteCreationDefault = (): UseMutationResult<
  void,
  AxiosError,
  DeleteSiteCreationDefaultParams
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => deleteSiteCreationDefault(params.siteCreationDefaultId),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries({ queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefaults] });
      await queryClient.invalidateQueries({
        queryKey: [SiteCreationDefaultCacheKeys.SiteCreationDefault, params.siteCreationDefaultId],
      });
      await queryClient.invalidateQueries({ queryKey: [SiteGroupCacheKeys.SiteGroups] });
    },
  });
};
